<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_user.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="role_id" :items="roles" :label="$t('edit_user.role')" item-text="name" item-value="id" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="required_client == false">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.employee_number" :label="$t('edit_user.employee_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.name" :label="$t('edit_user.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.paternal_surname" :label="$t('edit_user.paternal_surname')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.maternal_surname" :label="$t('edit_user.maternal_surname')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.email" disabled :label="$t('edit_user.email')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="required_client == false">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.position"  :label="$t('edit_user.position')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="required_client == true">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="user.client_id" :items="customers" :label="$t('edit_user.client')" item-text="name" item-value="id" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="required_client == false">
                            <v-col cols="6" class="py-0">
                                <v-file-input prepend-icon="cloud_upload" class="cloud_upload" :clearable="false" v-model="user.image" accept="*" :label="$t('edit_user.avatar')"></v-file-input>
                            </v-col>
                        </v-row> 
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('edit_user.warehouses')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                    <v-col cols="6" class="py-0" style="display:flex" v-for="(warehouse, i) of user.warehouses" :key="i">
                                        <v-checkbox class="mt-0" color="info" v-model="warehouse.selected" :label="warehouse.name"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_user.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'user.update'">
                                {{ $t('edit_user.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        rules_password(){return [
            v => !!v || this.$t('create_user.validations.password'),
            v => v.toString().length >= 8 || this.$t('create_user.validations.password')
        ]}
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            user:{
                image: [],
                name: '',
                email: '',
                employee_number: '',
                paternal_surname: '',
                maternal_surname: '',
                position: '',
                // password: '',
                // password_confirmation: '',
                roles: [],
                warehouse_id: '',
                warehouses: [],
                client_id: ''
            },
            role_id: null,
            roles: [],
            required_client: false,
            customers: [],
            warehouses: []
            // show: false,
            // show_c: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_user";
    },
    methods: {
        async index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            await this.$http.get(this.$store.state.chronosApi + 'roles', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data;
                response.data.forEach(x => {
                    x = x.attributes;
                    var exist = false;
                    x.permissions.forEach( y => {
                        y = y.attributes;
                        if(y.name == "customer.show") { exist = true; return; }
                    });
                    if(exist == true) this.roles.push({name: x.name, id: x.id, required_client: true});
                    else this.roles.push({name:x.name, id: x.id, required_client: false});
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            // 2 -> get all clients, 1 -> get national clients, 0 -> get international clients
            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/2', {headers: {Authorization: lsToken}})
            .then((response) =>{
                response.data = response.data.data;
                response.data.forEach( x => {
                    x = x.attributes;
                    this.customers.push({name: x.name, id: x.id});
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
            
            this.$http.get(this.$store.state.chronosApi + 'users/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.user.data.attributes;
                this.user.name = response.data.name;
                this.user.paternal_surname = response.data.paternal_surname;
                this.user.maternal_surname = response.data.maternal_surname;
                this.user.employee_number = response.data.employee_number;
                this.user.position = response.data.position ?? '';
                this.user.email = response.data.email;
                this.role_id = response.data.roles[0].attributes.id;
                this.user.client_id = response.data.client_id;
                if(response.data.image != null){
                    this.user.image = new File(["foo"], this.$t("edit_user.profile_picture"), {
                        type: "text/plain",
                    });
                }
                response.data.warehouses.forEach( x => {
                    this.warehouses.push({id: x.warehouse_id, selected: true})
                });
                this.$http.get(this.$store.state.chronosApi + 'warehouses', {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data.forEach(x => {
                        var exist = false;
                        this.warehouses.forEach( y => {
                            if(y.id == x.id) {
                                exist = true;
                            }
                        })
                        if(!exist) this.user.warehouses.push({name: x.name, id: x.id, selected: false});
                        else this.user.warehouses.push({name: x.name, id: x.id, selected: true});
                    })
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.user.roles.push({id: this.role_id});
            this.user.warehouses.forEach( x => {
                if(x.selected == true) {
                    this.user.warehouse_id = x.id
                    return;
                }
            })
            if(this.user.warehouse_id == ''){
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('edit_user.select_warehouse')
                }
            }
            else{
                const formData = new FormData();

                if(this.user.image != [] && this.user.image.type != 'text/plain' && this.user.image != "") formData.append("user[image]", this.user.image);
                formData.append("user[name]", this.user.name);
                formData.append("user[employee_number]", this.user.employee_number);
                formData.append("user[paternal_surname]", this.user.paternal_surname);
                formData.append("user[maternal_surname']", this.user.maternal_surname);
                formData.append("user[warehouse_id]", this.user.warehouse_id);
                formData.append("user[email]", this.user.email);
                formData.append("user[position]", this.user.position);
                if(this.user.client_id != '') formData.append("user[client_id]", this.user.client_id)

                this.user.warehouses.forEach((x, index) => {
                    formData.append("user[warehouses[" + index + "]]", JSON.stringify(x));
                })

                formData.append("user[roles[0]]", JSON.stringify({id: this.role_id}));

                this.$http.put(this.$store.state.chronosApi + 'userUpdate/' + this.id, formData, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_user.responses.success')
                    }
                    this.$router.push({ name: "UsersIndex" });  
                }).catch((err) => {
                    err
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('edit_user.responses.success')
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        'role_id': function (){
            if(this.role_id == undefined || this.role_id == null) {
                return;
            }
            else {
                const role = this.roles.find( x => x.id == this.role_id);
                if(role.required_client == true) this.required_client = true;
                else {
                    this.required_client = false;
                    this.user.client_id = '';
                }
            }
        }
    }
}
</script>

<style lang="css">
</style>