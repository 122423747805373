<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('carriers.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" :to="'/entrance/carriers/create'" v-permission="'carriers.show'">
                        {{ $t('carriers.register_carrier') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('carriers.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('carriers.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('carriers.no') }}</v-btn>
                            <v-btn color="error" @click="deletecarrier">
                                {{ $t('carriers.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDeparture" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('carriers.modal.departure') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('carriers.confirm_out_1') }} {{ this.carrier.tract_plates }} {{ $t('carriers.confirm_out_2') }} {{ this.hours }} : {{ this.minutes }} {{ $t('carriers.confirm_out_3') }} {{ this.day }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDeparture = false">{{ $t('carriers.cancel') }}</v-btn>
                            <v-btn class="white--text" color="amarillo" @click="updateDeparture">
                                {{ $t('carriers.accept') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalEntry" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('carriers.modal.entry') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('carriers.confirm_out_1_1') }} {{ this.carrier.tract_plates }} {{ $t('carriers.confirm_out_2_1') }} {{ this.hours }} : {{ this.minutes }} {{ $t('carriers.confirm_out_3') }} {{ this.day }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalEntry = false">{{ $t('carriers.cancel') }}</v-btn>
                            <v-btn class="white--text" color="amarillo" @click="updateEntry">
                                {{ $t('carriers.accept') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        headers(){
            return [
                { text: this.$t('carriers.carrier'), align: "center", value: 'carrier_line', class: 'text-uppercase' },
                { text: this.$t('carriers.drivers_name'), align: "center", value: 'drivers_name', class: 'text-uppercase' },
                { text: this.$t('carriers.tracto_plates'), align: "center", value: 'tract_plates', class: 'text-uppercase' },
                { text: this.$t('carriers.container'), align: "center", value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('carriers.arrival'), align: "center", value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('carriers.entry'), align: "center", value: 'entry_date', class: 'text-uppercase' },
                { text: this.$t('carriers.departure'), align: "center", value: 'departure_date', class: 'text-uppercase' },
                { text: this.$t('carriers.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            modalDeparture: false,
            modalEntry: false,
            idcarriers: '',
            carrier: {
                tract_plates: ""
            },
            actions: [
                {
                    name: this.$t('carriers.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                },
                {
                    name: this.$t('carriers.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: "carriers.update"
                },
                {
                    name: this.$t('carriers.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: "carriers.delete"
                },
                {
                    name: this.$t('carriers.register_entry'),
                    icon: {
                        color: 'accent',
                        icon: 'warehouse'
                    },
                    method: 'askEntry',
                    permission: "carriers.update"
                },
                {
                    name: this.$t('carriers.register_departure'),
                    icon: {
                        color: 'accent',
                        icon: 'logout'
                    },
                    method: null,
                    permission: "carriers.update"
                }
            ],
            props: {
                search: '',
                items: [],
            },
            day: '',
            hours: '',
            minutes: '',
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.carriers";
        this.day = new Date().getDate();
        if(this.day.toString().length == 1) this.day = "" + 0 + this.day;
        this.hours = new Date().getHours();
        if(this.hours.toString().length == 1) this.hours = "" + 0 + this.hours;
        this.minutes = new Date().getMinutes();
        if(this.minutes.toString().length == 1) this.minutes = "" + 0 + this.minutes;
        this.day = new Date().toLocaleDateString('en-GB');
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'carriers', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x =>{
                        x.arrival_date = x.arrival_date + " - " + x.arrival_time;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.actions[0].link = '/entrance/carriers/detail/' + x.id;
                        x.actions[1].link = '/entrance/carriers/edit/' + x.id;
                        
                        if(x.entry_date != null) {
                            x.entry_date = x.entry_date + " - " + x.entry_time;
                            x['actions'][3].method = null;
                            x['actions'][4].method = "askDeparture"
                        }
                        
                        if(x.departure_date != null) {
                            x.departure_date = x.departure_date + " - " + x.departure_time;
                            x['actions'][4].method = null;
                        }
                        
                        x['parameters'] = x;
                        this.props.items.push(x);
                    });
                this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(carriers){
            this.modalDelete = true;
            this.idcarriers = carriers.id;
        },
        askDeparture(carriers){
            this.modalDeparture = true;
            this.carrier.tract_plates = carriers.tract_plates;
            this.idcarriers = carriers.id;
        },
        askEntry(carriers){
            this.modalEntry = true;
            this.carrier.tract_plates = carriers.tract_plates;
            this.idcarriers = carriers.id;
        },
        deletecarrier(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'carriers/' + this.idcarriers, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        updateDeparture(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.carrier.departure_time = this.hours + ":" + this.minutes;
            this.carrier.departure_date = this.day;
            this.$http.put(this.$store.state.chronosApi + 'carriers/' + this.idcarriers, this.carrier,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_box.responses.success')
                }
                this.modalDeparture = false;
                this.index();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        updateEntry(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.carrier.entry_time = this.hours + ":" + this.minutes;
            this.carrier.entry_date = this.day;
            this.$http.put(this.$store.state.chronosApi + 'carriers/' + this.idcarriers, this.carrier,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_box.responses.success')
                }
                this.modalEntry = false;
                this.index();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        }
    }
}
</script>

<style>

</style>