<template>
    <div>
        <v-card>
            <v-card-text class="px-0 py-0">
                <!-- YEAR AND MONTH PICKER -->
                <v-row class="mx-4 pt-3" justify="start" align="start">
                    <v-col cols="3" class="my-2">
                        <client-selector class="py-0" ref="clientSelector" @getClients="v => items.clients = v" :yego_clients="true" :rules="rules_required"></client-selector>  
                    </v-col>
                    <v-col cols="2" class="my-2">
                        <v-autocomplete class="mt-0 py-0" v-model="current_year" :items="anios" :label="$t('dashboard.year')"></v-autocomplete> 
                    </v-col>
                    <v-col cols="2" class="my-2">
                        <v-autocomplete class="mt-0 py-0" v-model="current_month" item-value="value" item-text="name" :items="monthsNames" :label="$t('dashboard.month')"></v-autocomplete> 
                    </v-col>
                </v-row>

                <!-- FIRST ROW (DATA) -->
                <v-row class="mx-4 pt-0 mt-0" justify="start" align="start">
                    <v-col cols="3" class="my-2">
                        <v-card style="text-align: center; height: 160px !important; min-height: 160px !important; max-height: 160px !important;">
                            <v-card-text style="color: #009cde">
                                {{ $t('dashboard.total_sales') }}
                            </v-card-text>
                            <v-card-text style="font-size: 22px; font-weight: bold; text-align: center !important">
                                {{ total_sales.total }}
                            </v-card-text>
                            <v-card-text class="pt-0 mt-0 pb-6">
                                <p v-if="total_sales.upper" style="color: #4CAF50; display: inline;">↑ {{ total_sales.colored_text }}%</p> 
                                <p v-else style="color: #DE0000; display: inline;">↓ {{ total_sales.colored_text }}%</p>
                                <p style="display: inline;">{{ total_sales.text }}</p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3" class="my-2">
                        <v-card style="text-align: center; height: 160px !important; min-height: 160px !important; max-height: 160px !important;">
                            <v-card-text style="color: #009cde">
                                {{ $t('dashboard.sales_by_channel') }}
                            </v-card-text>
                            <v-row v-for="(channel, i) of channel_sales" :key="i" justify="center" align="center">
                                <p style="font-weight: bold; display: inline; margin-bottom: 2px;">{{ channel[0] }}: </p><p style="display: inline; margin-bottom: 2px;">{{ channel[1] }}</p>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="3" class="my-2">
                        <v-card style="text-align: center; height: 160px !important; min-height: 160px !important; max-height: 160px !important;">
                            <v-card-text style="color: #009cde">
                                {{ $t('dashboard.orders_by_channel') }}
                            </v-card-text>
                            <v-row v-for="(channel, i) of orders_by_channel" :key="i" justify="center" align="center">
                                <p style="font-weight: bold; display: inline; margin-bottom: 2px;">{{ channel[0] }}: </p><p style="display: inline; margin-bottom: 2px;">{{ channel[1] }}</p>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="3" class="my-2">
                        <v-card style="text-align: center; height: 160px !important; min-height: 160px !important; max-height: 160px !important;">
                            <v-card-text style="color: #009cde">
                                {{ $t('dashboard.total_orders') }}
                            </v-card-text>
                            <v-card-text style="font-size: 22px; font-weight: bold; text-align: center !important">
                                {{ total_orders.total }}
                            </v-card-text>
                            <v-card-text class="pt-0 mt-0 pb-6">
                                <p v-if="total_orders.upper" style="color: #4CAF50; display: inline;">↑ {{ total_orders.colored_text }}%</p> 
                                <p v-else style="color: #DE0000; display: inline;">↓ {{ total_orders.colored_text }}%</p>
                                <p style="display: inline;">{{ total_orders.text }}</p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- SECOND ROW (CHARTS) -->
                <v-row class="mx-4 pt-0 mt-0" justify="start" align="start">
                    <v-col cols="3" class="my-2">
                        <v-card-text style="color: #009cde">
                            {{ $t('dashboard.total_orders') }}
                        </v-card-text>
                        <line-chart v-if="complete" :height="250" :chart-data="line_chart_data" :options="line_chart_options"></line-chart>
                    </v-col>
                    <v-col cols="3" class="my-2">
                        <v-card-text style="color: #009cde">
                            {{ $t('dashboard.sales_breakdown_by_channel') }}
                        </v-card-text>
                        <bar-stacked-chart v-if="complete" :height="250" :chart-data="sales_chart_data" :options="bar_chart_options"></bar-stacked-chart>
                    </v-col>
                    <v-col cols="3" class="my-2">
                        <v-card-text style="color: #009cde">
                            {{ $t('dashboard.orders_breakdown_by_channel') }}
                        </v-card-text>
                        <bar-stacked-chart v-if="complete" :height="250" :chart-data="orders_chart_data" :options="bar_chart_options"></bar-stacked-chart>
                    </v-col>
                    <v-col cols="3" class="my-2">
                        <v-card style="text-align: center; height: 300px !important; min-height: 300px !important; max-height: 300px !important;">
                            <v-card-text style="color: #009cde">
                                {{ $t('dashboard.best_selling_products') }}
                            </v-card-text>
                            <v-row v-for="(product, i) of best_selling_products" :key="i" justify="center" align="center">
                                <p style="font-weight: bold; display: inline; margin-bottom: 2px;">{{ product.description }}: </p><p style="display: inline; margin-bottom: 2px;">{{ product.total_quantity }}</p>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- THIRD ROW (LONG CHART) -->
                <v-row class="mx-4 pt-0 mt-0" justify="start" align="start">
                    <v-col cols="3" class="my-2">
                        <v-card style="text-align: center; height: 300px !important; min-height: 300px !important; max-height: 300px !important;">
                            <v-card-text style="color: #009cde">
                                {{ $t('dashboard.best_selling_products_by_channel') }}
                            </v-card-text>
                            <v-row v-for="(channel, i) of best_selling_products_by_channel" :key="i" justify="center" align="center">
                                <p style="font-weight: bold; display: inline; margin-bottom: 2px;">{{ channel.channel}}: </p><p style="display: inline; margin-bottom: 2px;">{{ channel.product.description }}</p>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="9" class="my-2" justify="center" aling="center">
                        <v-card-text style="color: #009cde">
                            {{ $t('dashboard.sales_by_state') }}
                        </v-card-text>
                        <bar-stacked-chart v-if="complete" :height="250" :chart-data="sales_by_state_chart_data" :options="bar_chart_options"></bar-stacked-chart>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

import LineChart from './Charts/Line.js'
import BarStackedChart from './Charts/BarStacked.js'

export default {
    props: [
        'colors',
        'load'
    ],
    components: {
		LineChart,
        BarStackedChart
    },
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers(){
            return [
                { text: this.$t('dashboard.customer'), align: "start", value: 'name', class: 'text-uppercase', width: "25%"},
                { text: this.$t('dashboard.income_movements'), align: "center", value: 'income_movements', class: 'text-uppercase', width: "16.6%" },
                { text: this.$t('dashboard.outcome_movements'), align: "center", value: 'outcome_movements', class: 'text-uppercase', width: "16.6%" },
                { text: this.$t('dashboard.fulfillment'), align: "center", value: 'ff', class: 'text-uppercase', width: "16.6%" },
                { text: this.$t('dashboard.ffext'), align: "center", value: 'extff', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.returns'), align: "center", value: 'returns', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.total'), align: "center", value: 'total', class: 'text-uppercase', width: "8.3%" },
            ];
        } 
    },
    data(){
        return {
            total_sales: {
                total: "",
                performance: "",
                upper: false,
                colored_text: "",
                text: ""
            },
            channel_sales: [],
            orders_by_channel: [],
            total_orders: {
                total: "",
                performance: "",
                upper: false,
                colored_text: "",
                text: ""
            },
            line_chart_data: {
                labels: [],
                datasets: []
            },
            line_chart_options: {
                responsive: true,
                maintainAspectRatio: false
            },
            sales_chart_data: {
                labels: [],
                datasets: []
            },
            orders_chart_data: {
                labels: [],
                datasets: []
            },
            best_selling_products: [],
            best_selling_products_by_channel: [],
            sales_by_state_chart_data: {
                labels: [],
                datasets: []
            },
            current_year: "",
            current_month: "",
            anios: [ 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027],
            months: [
                { month: 1, es: 'Enero', es_short: 'Ene', en: 'January', en_short: 'Jan', days: 31},
                { month: 2, es: 'Febrero', es_short: 'Feb', en: 'February', en_short: 'Feb', days: 28},
                { month: 3, es: 'Marzo', es_short: 'Mar', en: 'March', en_short: 'Mar', days: 31},
                { month: 4, es: 'Abril', es_short: 'Abr', en: 'April',en_short: 'April', days: 30},
                { month: 5, es: 'Mayo', es_short: 'May', en: 'May', en_short: 'May', days: 31},
                { month: 6, es: 'Junio', es_short: 'Jun', en: 'June', en_short: 'June', days: 30},
                { month: 7, es: 'Julio', es_short: 'Jul', en: 'July', en_short: 'July', days: 31},
                { month: 8, es: 'Agosto', es_short: 'Ago', en: 'August', en_short: 'Aug', days: 31},
                { month: 9, es: 'Septiembre', es_short: 'Sep', en: 'September', en_short: 'Sept', days: 30},
                { month: 10, es: 'Octubre', es_short: 'oct', en: 'October', en_short: 'Oct', days: 31},
                { month: 11, es: 'Noviembre', es_short: 'Nov', en: 'November', en_short: 'Nov', days: 30},
                { month: 12, es: 'Diciembre', es_short: 'Dic', en: 'December', en_short: 'Dec', days: 31},
            ],
            monthsNames: [],
            complete: false,
            bar_chart_options: {
				scales: {
					xAxes: [{
						stacked: true,
                        padding: 0,
                        ticks: {
                            autoSkip: false,
                            minor: {
                                fontSize: 10
                            },
                            // maxRotation: 20,
                            // minRotation: 20
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
					}],
					yAxes: [{
						stacked: true,                
                        padding: 0,
                        ticks: {
                            beginAtZero: true,
                            minor: {
                                fontSize: 10
                            }
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        },
					},],
				},
				legend: { display: false, position: 'top'},
				maintainAspectRatio: false,
				cutoutPercentage: 0,
                responsive: true,
                lineTension: 1,
			}
        }
    },
    mounted(){
        this.$store.state.clientId = 111;

        if(this.$store.state.language == 'es') 
            for(var i = 1; i <= 12; i++){
                this.monthsNames.push({ name: this.months[i - 1].es, s_name: this.months[i - 1].es_short, value: i });
            }
        else 
            for(var j = 1; j <= 12; j++){
                this.monthsNames.push({ name: this.months[j - 1].en, s_name: this.months[j - 1].en_short, value: j });
            }

        this.getCurrentDate();

        if(this.load == 4) this.index();
    },
    methods: {
        getCurrentDate(){
            const cur_date = new Date();
            const cur_year = cur_date.getFullYear(); // Obtiene el año actual, por ejemplo, 2024
            const cur_month = cur_date.getMonth(); // Obtiene el mes actual (de 0 a 11), por lo que sumamos 1

            this.current_year = cur_year;
            this.current_month = this.monthsNames[cur_month].value;
        },
        async index(){
            this.$nextTick(async () => {
                this.$store.state.loaderProps.visible = true;

                this.loadedInformationSets = [];
                this.loadedDocumentsSets = [];

                await this.getTotalSales();
                await this.getSalesByChannel();
                await this.getOrdersByChannel();
                await this.getTotalOrders();
                await this.getDailyOrders(); // Line chart
                await this.bestSellingProducts();
                await this.bestSellingProductsByChannel();
                await this.getSalesByState();

                this.complete = true;
                
                this.$store.state.loaderProps.visible = false;
            });
        },
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        async getTotalSales(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/total_sales?client_id=' + this.$store.state.clientId + '&month=' + this.current_month + '&year=' + this.current_year, { headers: { Authorization: lsToken } })
			.then((response) => {
                this.total_sales = {
                    total: response.data.data.total,
                    performance: response.data.data.percentage_change,
                    upper: parseFloat(response.data.data.total.match(/[\d,.]+/g)[0].replace(/,/g, '')) >= parseFloat(response.data.data.total_last_year.match(/[\d,.]+/g)[0].replace(/,/g, '')) ? true : false,
                    colored_text: response.data.data.percentage_change.split("%")[0],
                    text: response.data.data.percentage_change.split("%")[1]
                }              
			}).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        async getSalesByChannel(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/sales_by_channel?client_id=' + this.$store.state.clientId + '&month=' + this.current_month + '&year=' + this.current_year, { headers: { Authorization: lsToken } })
			.then((response) => {
                this.channel_sales = Object.keys(response.data.data.channels).map((key) => [key, response.data.data.channels[key]]);
                
                var count = 0;
                
                const labels = Object.keys(response.data.data.channels).map((key) => {
                    count++;
                    return{ name: key, color: this.colors[count - 1], value: parseFloat(response.data.data.channels[key].match(/[\d,.]+/g)[0].replace(/,/g, ''))};
                })

                const datasets = [{
                    backgroundColor: labels.map(x => {
                        return x.color
                    }),
                    label: "",
                    data: labels.map(x => {
                        return parseFloat(response.data.data.channels[x.name].match(/[\d,.]+/g)[0].replace(/,/g, ''));
                    }),
                    borderColor: 'rgba(0, 0, 0, 0)',
                }]

                this.sales_chart_data = {
                    labels: labels.map(x => {
                        return x.name;
                    }),
                    datasets: datasets
                }
			}).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        async getOrdersByChannel(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/orders_by_channel?client_id=' + this.$store.state.clientId + '&month=' + this.current_month + '&year=' + this.current_year, { headers: { Authorization: lsToken } })
			.then((response) => {
                this.orders_by_channel = Object.keys(response.data.data.channels).map((key) => [key, response.data.data.channels[key]]);

                var count = 0;
                const labels = Object.keys(response.data.data.channels).map((key) => {
                    count++;
                    return { name: key, color: this.colors[count - 1], value: response.data.data.channels[key]};
                })

                const datasets = [{
                    backgroundColor: labels.map(x => {
                        return x.color
                    }),
                    label: "",
                    data: labels.map(x => {
                        return response.data.data.channels[x.name];
                    }),
                    borderColor: 'rgba(0, 0, 0, 0)',
                }]

                this.orders_chart_data = {
                    labels: labels.map(x => {
                        return x.name;
                    }),
                    datasets: datasets
                }
			}).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        async getTotalOrders(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/total_orders?client_id=' + this.$store.state.clientId + '&month=' + this.current_month + '&year=' + this.current_year, { headers: { Authorization: lsToken } })
			.then((response) => {
                this.total_orders = {
                    total: response.data.data.total,
                    performance: response.data.data.percentage_change,
                    upper: parseFloat(response.data.data.total) >= parseFloat(response.data.data.total_last_year) ? true : false,
                    colored_text: response.data.data.percentage_change.split("%")[0],
                    text: response.data.data.percentage_change.split("%")[1]
                }              
			}).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        async getDailyOrders(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/daily_orders?client_id=' + this.$store.state.clientId + '&month=' + this.current_month + '&year=' + this.current_year, { headers: { Authorization: lsToken } })
			.then((response) => {
                const uniqueDays = Array.from(new Set([
                    ...response.data.data.current_year.map(item => item.day_range),
                    ...response.data.data.last_year.map(item => item.day_range)
                ]));

                this.line_chart_data.datasets = [];

                const dataset1 = {
                    label: this.monthsNames[this.current_month - 1].name + " " + this.current_year,
                    borderColor: "#009cde",
                    lineTension: 0,
                    pointRadious: 1,
                    data: []
                }

                const dataset2 = {
                    label: this.monthsNames[this.current_month - 1].name + " " + (parseInt(this.current_year) - 1).toString(),
                    lineTension: 0,
                    borderColor: "#D91136",
                    pointRadious: 1,
                    data: []
                }

                uniqueDays.forEach(x => {
                    const current_year_result = response.data.data.current_year.find(y => y.day_range == x)
                    const last_year_result = response.data.data.last_year.find(y => y.day_range == x)

                    if(current_year_result != undefined) dataset1.data.push(current_year_result.count);
                    else dataset1.data.push(0);

                    if(last_year_result != undefined) dataset2.data.push(last_year_result.count);
                    else dataset2.data.push(0)
                })

                this.line_chart_data = {
                    labels: uniqueDays,
                    datasets: [dataset1, dataset2]
                };                
			}).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },  
        async bestSellingProducts(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/best_selling_products?client_id=' + this.$store.state.clientId + '&month=' + this.current_month + '&year=' + this.current_year, { headers: { Authorization: lsToken } })
			.then((response) => {
                this.best_selling_products = response.data.data.products;
			}).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        }, 
        async bestSellingProductsByChannel(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/best_selling_products_by_channel?client_id=' + this.$store.state.clientId + '&month=' + this.current_month + '&year=' + this.current_year, { headers: { Authorization: lsToken } })
			.then((response) => {
                this.best_selling_products_by_channel = response.data.data.channels;
			}).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        async getSalesByState(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/sales_by_state?client_id=' + this.$store.state.clientId + '&month=' + this.current_month + '&year=' + this.current_year, { headers: { Authorization: lsToken } })
			.then((response) => {

                const labels = []
                response.data.data.states.forEach(x => {
                    labels.push({ name: x.state, color: this.colors[4], value: x.total})
                })

                const datasets = [{
                    backgroundColor: labels.map(x => {
                        return x.color
                    }),
                    label: "",
                    data: labels.map(x => {
                        return x.value;
                    }),
                    borderColor: 'rgba(0, 0, 0, 0)',
                }]

                this.sales_by_state_chart_data = {
                    labels: labels.map(x => {
                        return x.name;
                    }),
                    datasets: datasets
                }
			}).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
    },
    watch: {
        'current_year': function(){
            if(this.load == 4) this.index();
        },
        'current_month': function(){
            if(this.load == 4) this.index();
        },
        '$store.state.clientId': function(){
            if(this.load == 4) this.index();
        }
    }
}
</script>

<style>

</style>