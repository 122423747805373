<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_relocation.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="addSku">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="relocation.comment" :label="$t('create_relocation.comments')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.origin.locationId" :loading="origin_locations_loader" item-value="id" item-text="name" :items="locations" :label="$t('create_relocation.origin_location')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.product_id" item-value="id" item-text="sku" :items="skus" :label="$t('create_relocation.sku')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.origin.pallet" item-value="id" item-text="name" :items="pallets" :label="$t('create_relocation.origin_pallet')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.origin.box" item-value="id" item-text="name" :items="boxes" :label="$t('create_relocation.origin_box')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.batch" :items="batches" :label="$t('create_relocation.batch')"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.reference" :items="references" :label="$t('create_relocation.expiration')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-checkbox class="mt-0" color="info" v-model="relocation.all" :label="$t('create_relocation.select_all')"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="relocation.destination.locationId" item-value="locationId" item-text="name" :items="free_locations" :label="$t('create_relocation.destination_location')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="relocation.destination.pallet" :label="$t('create_relocation.destination_pallet')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0" justify="center">
                            <v-col cols="6" justify="end" align="end">
                                <v-btn outlined class="right white--text" color="amarillo" @click="addSku">
                                    {{ $t('create_relocation.add') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="center" align="center">
                            <datatable :props="{headers: headers, items: props.show_items}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_relocation.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'relocation.create'">
                                {{ $t('create_relocation.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers: function (){
            return [
                { text: this.$t('create_relocation.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('create_relocation.pallet'), align: "center", value: 'origin.pallet', class: 'text-uppercase' },
                { text: this.$t('create_relocation.box'), align: "center", value: 'origin.box', class: 'text-uppercase' },
                { text: this.$t('create_relocation.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('create_relocation.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('create_relocation.expiration'), align: "center", value: 'reference', class: 'text-uppercase' },
                { text: this.$t('create_relocation.origin'), align: "center", value: 'origin.locationName', class: 'text-uppercase' },
                { text: this.$t('create_relocation.destination'), align: "center", value: 'destination.locationName', class: 'text-uppercase' },
                { text: "", align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        }
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            }, 
            actions: [
                {
                    name: this.$t('edit_appointment.product_details.delete_sku'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteSku',
                }
            ],
            relocation: {
                movementId: "",
                warehouseId: this.$store.state.warehouseId,
                comment: "",
                product_id: "",
                quantity: "",
                sku: "",
                origin: {
                    locationId: "",
                    locationName: "",
                    pallet: "",
                    box: "",
                },
                destination:{
                    locationId: "",
                    locationName: "",
                    pallet: "",
                    box: ""
                },
                all: false,
                batch: "",
                reference: "", // Expiration
            },
            origin_locations_loader: true,
            props: {
                items: [],
                show_items: []
            },
            locations: [],
            free_locations: [],
            bussy_locations_info: [],
            skus: [],
            pallets: [],
            boxes: [],
            batches: [],
            references: [],
            quantities: [],
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_relocation";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'relocations/getEmptyLocations?warehouse=' + this.$store.state.warehouseId, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.free_locations = response.data.data;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
            })

            this.$http.get(this.$store.state.chronosApi + 'relocations/getBusyLocations?warehouse=' + this.$store.state.warehouseId, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.origin_locations_loader = true;
                response.data.data.forEach( x => {
                    this.locations.push({
                        id: x.locationId,
                        name: x.name
                    });
                });
                this.bussy_locations_info = response.data.data;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
                this.origin_locations_loader = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items.forEach( x => {
                x.warehouseId = parseInt(x.warehouseId);
                x.product_id = parseInt(x.product_id);
                x.origin.locationId = parseInt(x.origin.locationId);
                x.destination.locationId = parseInt(x.destination.locationId);
            })

            this.$http.post(this.$store.state.chronosApi + 'relocations', {relocations: this.props.show_items}, {headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.$router.push({ name: 'RelocationsIndex' });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
        addSku: async function (){
            var validate = this.$refs.form.validate();
            const lsToken = localStorage.getItem("access_token");
            
            if(validate){             
                
                this.$store.state.loaderProps.visible = true;

                if(this.relocation.all == true){
                    
                    var currentRelocation = {}
                    var result = "";
                    const location_info = this.bussy_locations_info.find( element => element.locationId == this.relocation.origin.locationId && element);                    
                    for(let x of location_info.detailed_stock){
                        if( x.quantity > 0 || x.reserved > 0){
                            if(x.pallet_per_location.pallet == this.relocation.origin.pallet){
                                this.relocation.movementId = this.makeid(36);
                                this.relocation.warehouseId = this.$store.state.warehouseId;
                                this.relocation.product_id = x.product_id;
                                this.relocation.quantity = x.quantity;
                                this.relocation.reserved = x.reserved;
                                this.relocation.sku = x.product.sku;
                                this.relocation.origin.box = x.box;
                                this.relocation.origin.pallet = x.pallet_per_location.pallet;
                                this.relocation.destination.box = x.box;
                                this.relocation.batch = x.batch;
                                this.relocation.reference = x.reference; // Expiration
                                
                                this.relocation['actions'] = JSON.parse(JSON.stringify(this.actions));
                                this.relocation['parameters'] = this.relocation.movementId;
                                currentRelocation = JSON.parse(JSON.stringify(this.relocation));                                
                                result = await this.validate(currentRelocation, lsToken);

                                if(result.results == "Is Validate") this.props.show_items.push(currentRelocation);
                                else{
                                    this.snackbar = {
                                        visible: true,
                                        color: 'warning',
                                        text: this.$t('create_relocation.responses.box_not_valid') + ', ' + this.$t('create_relocation.box') + ': ' + currentRelocation.destination.box + ', ' + this.$t('create_relocation.pallet') + ': ' + currentRelocation.destination.locationId
                                    }
                                }
                            }
                        }
                    }

                    this.$refs.form.reset();
                } else {      
                    this.relocation.movementId = this.makeid(36);
                    // this.relocation.destination.pallet = this.relocation.origin.pallet;
                    this.relocation.destination.box = this.relocation.origin.box;
                    
                    this.relocation['actions'] = JSON.parse(JSON.stringify(this.actions));
                    this.relocation['parameters'] = this.relocation.movementId;
                    currentRelocation = JSON.parse(JSON.stringify(this.relocation));                                

                    if(!this.props.items.find(x => (x.origin.locationId == this.relocation.origin.locationId && x.origin.pallet == this.relocation.origin.pallet && x.destination.locationId == this.relocation.destination.locationId && x.destination.pallet == this.relocation.destination.pallet && x.origin.box == this.relocation.origin.box))){
                        this.$http.post(this.$store.state.chronosApi + 'relocations/validateSku', {relocation: currentRelocation}, {headers: {Authorization: lsToken}})
                        .then( response => {
                            if(response.data.results == "Is Validate"){
                                this.props.show_items.push(currentRelocation);
                            } 
                            else this.snackbar = {
                                    visible: true,
                                    color: 'warning',
                                    text: this.$t('create_relocation.responses.box_not_valid') + ', ' + this.$t('create_relocation.box') + ': ' + currentRelocation.destination.box + ', ' + this.$t('create_relocation.pallet') + ': ' + currentRelocation.destination.locationId
                                }
                        }).catch( err => {
                            console.log(err)
                        })
                    }
                    else{
                        this.snackbar = {
                            visible: true,
                            color: 'warning',
                            text: this.$t('edit_appointment.responses.repeated')
                        }
                    }
                    this.$refs.form.reset();
                }
                this.$store.state.loaderProps.visible = false;
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_appointment.responses.error')
                }
            }
        },
        validate: async function (currentRelocation, lsToken){
            const {data} = await this.$http.post(this.$store.state.chronosApi + 'relocations/validateSku', {relocation: currentRelocation}, {headers: {Authorization: lsToken}})
            return data;
        },
        makeid(length) {
            var result           = '';
            var characters       = '-ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        deleteSku(id){
            this.props.show_items = this.props.show_items.filter(function(obj){
                return obj.movementId !== id;
            });
        },
        // getKeys(data) {
        //     return Object.entries(data).reduce((r, [key, value]) => {
        //         r.push(key)
        //         if (typeof value === 'object') r.push(this.getKeys(value))
        //         return r
        //     }, [])
        // }
    },
    watch: {
        "relocation.origin.locationId": function(){
            if(this.relocation.origin.locationId != null && this.relocation.origin != ""){
                this.pallets = [];
                this.relocation.product_id = "";
                this.boxes = [];
                this.skus = [];
                this.batches = [];
                this.references = [];
                const location_info = this.bussy_locations_info.find( element => element.locationId == this.relocation.origin.locationId );
                this.relocation.origin.locationName = location_info.name;
                location_info.detailed_stock.forEach( x => {
                    if(x.quantity > 0  || x.reserved > 0){
                        this.skus.push({
                            id: x.product_id,
                            sku: x.product.sku
                        });
                    }
                    this.pallets.push(x.pallet_per_location.pallet);
                    this.batches.push(x.batch);
                    this.references.push(x.reference);
                    this.boxes.push(x.box);
                })
            }  
        },
        "relocation.product_id": function(){
            if(this.relocation.product_id != null && this.relocation.product_id != ""){
                this.pallets = [];
                this.boxes = [];
                this.batches = [];
                this.references = [];
                this.quantities = [];
                this.reserved = [];
                const location_info = this.bussy_locations_info.find( element => element.locationId == this.relocation.origin.locationId );

                location_info.detailed_stock.forEach( x => {
                    if(x.product_id == this.relocation.product_id && (x.quantity > 0 || x.reserved > 0)){
                        console.log(x);
                        this.relocation.sku = x.product.sku
                        this.pallets.push(x.pallet_per_location.pallet);
                        this.batches.push(x.batch);
                        this.references.push(x.reference);
                        this.boxes.push(x.box);
                        this.quantities.push(x.quantity);
                        this.reserved.push(x.reserved);
                    }
                })
            }
        },
        "relocation.destination.locationId": function(){
            if(this.relocation.destination.locationId != null && this.relocation.destination.locationId != ""){
                const location_info = this.free_locations.find( element => element.locationId == this.relocation.destination.locationId );
                this.relocation.destination.locationName = location_info.name;
            }
        },
        "relocation.origin.pallet": function(){
            if(this.relocation.origin.pallet != null && this.relocation.origin.pallet != ""){
                this.boxes = [];
                this.batches = [];
                this.references = [];
                this.quantities = [];
                this.reserved = [];
                const location_info = this.bussy_locations_info.find( element => element.locationId == this.relocation.origin.locationId && element);

                location_info.detailed_stock.forEach( x => {
                    if(x.product_id == this.relocation.product_id && (x.quantity > 0 || x.reserved > 0)){
                        this.relocation.sku = x.product.sku
                        this.boxes.push(x.box);
                        this.batches.push(x.batch);
                        this.references.push(x.reference);
                        this.quantities.push(x.quantity);
                        this.reserved.push(x.reserved);
                    }
                })

                if(this.relocation.origin.pallet != null && this.relocation.origin.pallet != ""){
                    var i = this.pallets.indexOf(this.relocation.origin.pallet, 0)
                    this.relocation.batch = this.batches[i];
                    this.relocation.reference = this.references[i];
                    this.relocation.origin.box = this.boxes[i];
                    this.relocation.quantity = this.quantities[i];
                    this.relocation.reserved = this.reserved[i];
                }
            }
        },
        "relocation.origin.box": function(){
            if(this.relocation.origin.box != null && this.relocation.origin.box != ""){
                this.batches = [];
                this.references = [];
                this.quantities = [];
                this.reserved = [];
                const location_info = this.bussy_locations_info.find( element => element.locationId == this.relocation.origin.locationId );
                location_info.detailed_stock.forEach( x => {
                    if(x.product_id == this.relocation.product_id && (x.quantity > 0 || x.reserved > 0)){

                        this.relocation.sku = x.product.sku;
                        this.batches.push(x.batch);
                        this.references.push(x.reference);
                        this.quantities.push(x.quantity);
                        this.reserved.push(x.reserved);
                    }
                })
                if(this.relocation.origin.box != null && this.relocation.origin.box != ""){
                    var i = this.boxes.indexOf(this.relocation.origin.box, 0)

                    this.relocation.batch = this.batches[i];
                    this.relocation.reference = this.references[i];
                    this.relocation.origin.pallet = this.pallets[i];
                    this.relocation.quantity = this.quantities[i];
                    this.relocation.reserved = this.reserved[i];
                }
            }
        }
    }
}
</script>

<style lang="css">
</style>