<template>
   <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="6" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillments_prepaid_returns.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="right white--text mr-2" style="max-width: 40px !important; min-width: 40px !important" color="amarillo" @click="modalOrdersIdentifier = true">
                                <v-icon class="material-icons-outlined" small>content_paste_search</v-icon>
                            </v-btn>
                        </template>

                        <span>{{$t('fulfillments_cod_returns.orders_identifier')}}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="right white--text mr-2" style="max-width: 40px !important; min-width: 40px !important" color="amarillo" @click="modalProductsSummary = true">
                                <v-icon class="material-icons-outlined" small>content_paste</v-icon>
                            </v-btn>
                        </template>

                        <span>{{$t('fulfillments_cod_returns.products_summary')}}</span>
                    </v-tooltip>
                    <v-btn class="right white--text tools-view-height white--text" outlined color="amarillo" @click="modalUploadreturn = true">
                        {{ $t('fulfillments_prepaid_returns.upload_returned') }}
                        <v-icon class="material-icons-outlined px-1" small>cloud_upload</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="2" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="modalUploadIncidences = true">
                        {{ $t('fulfillments_prepaid_returns.upload_returns') }}
                        <v-icon class="material-icons-outlined px-1" small>cloud_upload</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: false,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="uon"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> 
            <v-dialog v-model="modalUploadreturn" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillments_prepaid_returns.modal.upload_returned') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                        <v-card-text>
                            <br>
                            <v-row align="center" justify="center">
                                <v-col cols="10" class="py-0">
                                    <v-menu ref="initial_date" v-model="menu" :close-on-content-click="false" :return-value.sync="returned.date" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field class="py-0" v-model="returned.date" :label="$t('fulfillments_prepaid_returns.date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="returned.date" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                                            <v-btn text color="primary" @click="$refs.initial_date.save(returned.date)">{{ $t('fulfillments_prepaid_returns.save') }}</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col cols="10" class="py-0 my-0">
                                    <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                                </v-col>
                            </v-row>
                            <v-row  justify="center" align="center">
                                <v-col cols="9" class="py-0">
                                    <v-text-field class="py-0" v-model="returned.unique_order_number" :label="$t('fulfillments_prepaid_returns.unique_order_number')" :rules="rules_required"></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn color="amarillo" small class="px-0 mx-0" outlined @click="getOrderInformation();">
                                        <v-icon class="px-0 mx-0" small>search</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <br>
                            <br>
                            <span>
                                <v-row v-for="(product, i) of returned.products" :key="i" justify="center" align="center">
                                    <v-col cols="5" class="py-0">
                                        <label>{{ product.sku }} x {{ product.real }}</label>
                                    </v-col>
                                    <v-col cols="5" class="py-0">
                                        <v-autocomplete class="mt-0 py-0" v-model="product.name" :items="available_locations" item-value="name" item-text="name" :label="$t('fulfillments_prepaid_returns.select_location')" :rules="rules_required"></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </span>
                            
                            <br>
                            <div class="d-flex">
                                <v-spacer></v-spacer>
                                <v-btn class="mx-3" text @click="cancelReturn()">{{ $t('fulfillments_prepaid_returns.back') }}</v-btn>
                                <v-btn color="amarillo" class="white--text" @click="uploadReturn()">
                                    {{ $t('fulfillments_prepaid_returns.save') }}
                                </v-btn>
                            </div>
                            <br>
                        </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>   
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillments_prepaid_returns.modal.delete') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillments_prepaid_returns.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('fulfillments_prepaid_returns.no') }}</v-btn>
                            <v-btn color="error" @click="restoreOrder">
                                {{ $t('fulfillments_prepaid_returns.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillments_prepaid_returns.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_prepaid_returns.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_returns.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_prepaid_returns.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>

                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_prepaid_returns.tracking_history') }}</label></div>
                    <div v-for="(status, i) of orderToShow.statuses" :key="i" class="detail_content1"><label style="font-weight:bold">{{ status.status }}</label> <label>{{ status.date }}</label></div>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn  color="amarillo" class="white--text" @click="modalDetail = false">
                                {{ $t('fulfillments_prepaid_returns.go_back') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalUploadIncidences" persistent max-width="90%" >
                <v-card style="min-width: 500px">
                    <v-card-title class="justify-center">
                        {{ $t('fulfillments_prepaid_returns.upload_returns') }}
                    </v-card-title>
                    <v-stepper v-model="stepper2">
                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row class="mx-0 px-0" justify="center" align="center">
                                            <v-col cols="10">
                                                <v-file-input v-model="inputFile2" accept=".csv" :label="$t('fulfillments_prepaid_returns.returns_file')" append-outer-icon="attach_file" prepend-icon="null" :rules="rules_required"></v-file-input>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper2 = 4">
                                                            <v-icon class="material-icons-outlined" aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('fulfillments_prepaid_returns.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                <v-row class="mx-0 px-5 mx-5 py-0" justify="end" align="end">
                                    <v-col cols="2" justify="end" align="end" class="mr-8">
                                        <v-btn text  @click="modalUploadIncidences = false;" >
                                            {{ $t('fulfillments_prepaid_returns.cancel') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" justify="end" align="end">
                                        <v-btn class="white--text" color="amarillo" @click="uploadReturns" >
                                            {{ $t('fulfillments_prepaid_returns.load') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-card-text class="my-0 py-0">
                                        <p class="my-0 py-0">{{readRows2.length}} {{ $t('fulfillments_prepaid_returns.orders_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ $t('fulfillments_prepaid_returns.' + th) }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows2" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="saveFile">
                                        {{ $t('fulfillments_prepaid_returns.save_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <v-card flat class="mb-5">
                                    <v-simple-table v-if="this.responses.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillments_prepaid_returns.order') }}</th>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillments_prepaid_returns.status') }}</th>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillments_prepaid_returns.message') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in responses" :key="i">
                                                    <td>{{ item.order }}</td>
                                                    <td>{{ item.status }}</td>
                                                    <td>{{ item.message }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-btn class="px-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []; modalUploadIncidences = false;">{{ $t('fulfillments_prepaid_returns.close') }}</v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="4">
                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                    <p class="py-0 my-0">4.- El archivo de ubicaciones debe ser formato CSV.</p> 
                                    <p>5.- Si el campo presenta la leyenda 'Opcional' este campo puede ir vacío.</p> 
                                    <p class="py-0 my-0">Columnas: </p>
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                   <th class="text-left" style="padding: 0 5px !important;">order</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">date</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">rack</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1829839</td>
                                                    <td>2024-06-29</td>
                                                    <td>Bahia04</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepper2 = 1">
                                            {{ $t('products.go_back') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalOrdersIdentifier" persistent max-width="90%" >
                <v-card style="min-width: 500px">
                <v-card-title class="justify-center">
                    {{ $t('fulfillments_prepaid_returns.orders_identifier') }}
                </v-card-title>
                    <v-stepper v-model="stepper2">
                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row class="mx-0 px-0" justify="center" align="center">
                                            <v-col cols="10">
                                                <v-file-input v-model="orders_identifier.file" accept=".csv" :label="$t('fulfillments_prepaid_returns.guides_file')" append-outer-icon="attach_file" prepend-icon="null" :rules="rules_required"></v-file-input>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" v-bind="attrs" v-on="on" @click="stepper2 = 2">
                                                            <v-icon class="material-icons-outlined" aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('fulfillments_prepaid_returns.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card> 
                                <v-row class="pr-8 mr-8 py-0" justify="end" align="end">
                                    <v-col cols="2" justify="end" align="end" class="mr-10">
                                        <v-btn text  @click="modalOrdersIdentifier = false;" >
                                            {{ $t('fulfillments_prepaid_returns.cancel') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" justify="end" align="end">
                                        <v-btn class="white--text" color="amarillo" @click="exportIdentifier()" >
                                            {{ $t('fulfillments_prepaid_returns.import') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!-- <div class="d-flex">
                                    <v-btn class="px-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []; modalUploadIncidences = false;">{{ $t('fulfillments_prepaid_returns.close') }}</v-btn>
                                </div> -->
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-card-text>
                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                    <p class="py-0 my-0">4.- El archivo de compromisos debe ser formato CSV.</p> 
                                    <p class="py-0 my-0">Columnas: </p>
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left" style="padding: 0 5px !important;">tracking_guide</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>391793712572</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepper2 = 1">
                                            {{ $t('yego_reports.close') }}
                                        </v-btn>
                                    </v-row>
                                </v-card-text>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalProductsSummary" persistent max-width="90%" >
                <v-card style="min-width: 500px">
                <v-card-title class="justify-center">
                    {{ $t('fulfillments_prepaid_returns.products_summary') }}
                </v-card-title>
                    <v-stepper v-model="stepper">
                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row class="mx-0 px-0" justify="center" align="center">
                                            <v-col cols="10">
                                                <v-file-input v-model="products_by_orders_summary.file" accept=".csv" :label="$t('fulfillments_prepaid_returns.guides_file')" append-outer-icon="attach_file" prepend-icon="null" :rules="rules_required"></v-file-input>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" v-bind="attrs" v-on="on" @click="stepper = 2">
                                                            <v-icon class="material-icons-outlined" aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('fulfillments_prepaid_returns.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                <v-row class="mx-2 px-8 mx-5 py-0" justify="end" align="end">
                                    <v-col cols="2" justify="end" align="end" class="mr-10">
                                        <v-btn text  @click="modalProductsSummary = false;" >
                                            {{ $t('fulfillments_prepaid_returns.cancel') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2" justify="end" align="end">
                                        <v-btn class="white--text" color="amarillo" @click="productsByOrdersSummary()" >
                                            {{ $t('fulfillments_prepaid_returns.upload') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!-- <div class="d-flex">
                                    <v-btn class="px-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []; modalUploadIncidences = false;">{{ $t('fulfillments_prepaid_returns.close') }}</v-btn>
                                </div> -->
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                <p class="py-0 my-0">4.- El archivo de compromisos debe ser formato CSV.</p> 
                                <p class="py-0 my-0">Columnas: </p>
                                <v-simple-table fixed-header>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left" style="padding: 0 5px !important;">order_number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>38920190</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-row align="center" justify="center" class="my-3">
                                    <v-btn color="amarillo" class="white--text" @click="stepper = 1">
                                        {{ $t('yego_reports.close') }}
                                    </v-btn>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <v-card-text>
                                    <p class="py-0 my-0">{{ ordersLength }} {{ $t('yego_reports.orders_in_file') }} </p> 
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>

                                            <thead>
                                                <tr>
                                                    <th class="text-left" style="padding: 0 5px !important;">{{ $t('yego_reports.products') }}</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">{{ $t('yego_reports.quantity')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in productsSummary" :key="i">
                                                    <td>{{ item.sku }}</td>
                                                    <td>{{ item.quantity }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="modalProductsSummary = false; results = []">
                                            {{ $t('yego_reports.accept') }}
                                        </v-btn>
                                    </v-row>
                                </v-card-text>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-dialog>
        </v-container>    
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>

    </div>
</template>
<script>

import FileSaver from 'file-saver';

export default {
	computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('fulfillments_prepaid_returns.order'), value: 'uniqueOrderNumber' },
                { text: this.$t('fulfillments_prepaid_returns.client'), value: 'client.name' },
                { text: this.$t('fulfillments_prepaid_returns.shipping_number'), value: 'shipmentNumber' },
                { text: this.$t('fulfillments_prepaid_returns.return'), value: 'charge.lastUpdatedAt' },
                { text: this.$t('fulfillments_prepaid_returns.actions'), value: 'actions' },
            ];
        }
    },
    data(){
        return {
            shipping_service: this.$route.params.shipping_service,
            props: {
                search: '',
                items: [],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            modalDetail: false,
            modalUploadIncidences: false,
            modalOrdersIdentifier: false,
            modalProductsSummary: false,
            orderToDelete: '',
            orderToShow: '',
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            stepper2: 1,
            stepper: 1,
            responses: [],
            sTHeaders2:'',
            available_locations: [],
            modalUploadreturn: false,
            menu: false,
            return_is_valid: false,
            orders_identifier:{
                file: []
            },
            products_by_orders_summary:{
                file: []
            },
            ordersLength: "",
            productsSummary: "",
            returned: {
                unique_order_number: "",
                warehouse_id: this.$store.state.warehouseId,
                client_id: "",
                date: "",
                products: []
            },
            actions:[
                {
                    name: this.$t('fulfillments_prepaid_returns.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillments_prepaid_returns.incidence'),
                    icon: {
                        color: 'accent',
                        icon: 'warning_amber'
                    },
                    link: '/shipments/cod/incidence/10000',
                },
                {
                    name: this.$t('fulfillments_prepaid_returns.restore'),
                    icon: {
                        color: 'accent',
                        icon: 'restore'
                    },
                    method: 'askRestore',
                },
            ]
        }
    },
    mounted(){
        this.$store.state.module = "headers." + this.shipping_service + "_prepaid_returns";
        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.$http.get(this.$store.state.chronosApi + 'shipment/getReturnedOrders?warehouse=' + this.$store.state.warehouseId + '&shipping_service=' + this.shipping_service.toUpperCase() + '&cod=false', {headers: {Authorization: lsToken}})
            .then((response) => {
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => {
                    x = x.attributes;
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x['actions'][1].link = '/shipments/prepaid/incidence/' + x.id;
                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.$store.state.loaderProps.visible = false;
                this.orderToShow = response.data;
                this.modalDetail = true;
                // response.data.statuses.forEach( x => {
                //     this.orderToShow['statuses'].push({
                //         status: x.status,
                //         date: (x.date != null && x.time != null) ? new Date(x.date) : x.created_at
                //     })
                // })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        supplyOrder(order){
            console.log(order.uon);

        },
        askRestore(order){
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        restoreOrder(){
            console.log(this.orderToDelete);
        },
        askUpload(){
            this.modalUploadIncidences = true;
        },
        uploadReturns(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2);
            }
        },
        exportIdentifier(){
            this.createInput(this.orders_identifier.file, "identifier");
        },
        productsByOrdersSummary(){
            this.createInput(this.products_by_orders_summary.file, "summary");
        },
        createInput(file, type) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows = result.split('\r\n');
                    this.readRows.shift();

                    if(type == "identifier") this.downloadIdentifier();
                    else if(type == "summary") this.getProductsByOrder();
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        downloadIdentifier(){
            this.$store.state.loaderProps.visible = true
            const guides = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    guides.push(num[0]);
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'orders_identifier', {guides: guides}, { headers: { Authorization: lsToken }, responseType:'blob' })
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data,'Guías_Identificadas.xlsx');
                    this.modalOrdersIdentifier = false;
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.state.loaderProps.visible = false;
                    this.modalOrdersIdentifier = false;

                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }  
        },
        getProductsByOrder(){
            this.ordersLength = 0;
            this.$store.state.loaderProps.visible = true
            const guides = [];
            if(this.readRows.length > 0){
                this.readRows.forEach((x) => {
                    const num = x.split(',');
                    guides.push(num[0]);
                })
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'products_by_orders_summary', {guides: guides}, { headers: { Authorization: lsToken }})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    this.stepper = 3;
                    this.productsSummary = response.data.data;
                    this.ordersLength = response.data.orders;
                    this.products_by_orders_summary.file = [];
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.error_address')
                };
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].toLowerCase().replace(' ', '').split(',');
                    this.readRows2.shift();
                    this.stepper2 = 2;
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveFile(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            var formData = new FormData();
            formData.append("file", this.inputFile2);
            this.$http.post(this.$store.state.chronosApi + 'shipment/returnOrders/file', formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: lsToken} } )
            .then( response => {
                this.$store.state.loaderProps.visible = false;
                this.responses = response.data
                this.stepper2 = 3;
            })
            .catch(error => {
                console.log(error)
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancelReturn(){
            this.modalUploadreturn = false;
            this.returned = {
                unique_order_number: "",
                warehouse_id: this.$store.state.warehouseId,
                client_id: "",
                date: "",
                products: []
            };
        },
        getOrderInformation(){
            this.available_locations = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getProductsWithRackFromOrder?client_id=' + this.$store.state.clientId + '&unique_order_number=' + this.returned.unique_order_number, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.$store.state.loaderProps.visible = false;
                this.returned.products = response.data.products;
                this.available_locations = response.data.available_locations;
            }).catch((err) => {
                console.log(err);
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillments_prepaid_returns.order_not_found')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        uploadReturn(){
            const lsToken = localStorage.getItem("access_token");
            this.returned.client_id = this.$store.state.clientId;
            this.return_is_valid = false;
            this.returned.products.forEach( x => {
                if(x.name != "" && x.name != undefined) this.return_is_valid = true;
            })

            if(this.return_is_valid == false){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillments_prepaid_returns.return_not_valid')
                }
                return;
            }

            this.returned.products.forEach( x => {
                x.rack = x.name;

                delete x.name;
                delete x.real;
                delete x.sku;
                delete x.id;
            })
            
            this.$http.post(this.$store.state.chronosApi + 'shipment/returnOrders', {returns: [this.returned]}, {headers: {Authorization: lsToken}} )
            .then( response => {
                response.data.forEach(x => {
                    if(x.status == "error"){
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: x.message
                        }
                        this.$store.state.loaderProps.visible = false;
                        return;
                    }
                })
                this.returned = {
                    unique_order_number: "",
                    warehouse_id: this.$store.state.warehouseId,
                    client_id: "",
                    date: "",
                    products: []
                };
                this.$store.state.loaderProps.visible = false;
                this.modalUploadreturn = false;
                this.index();
            })
            .catch(error => {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: error.data.message
                }
                this.$store.state.loaderProps.visible = false;
            })
        }
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        },
    }
}
</script>

<style lang="scss">
    
</style>