<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="3" md="3" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillment_eon_pendings.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="9" align="end" class="py-0">
                    <v-row align="end" justify="end" class="px-3 py-3">
                        <v-btn class="right white--text tools-view-height mr-1" color="amarillo" @click="modalImport = true">
                            {{ $t('fulfillment_eon_pendings.import_orders') }}
                            <v-icon class="px-1" small>cloud_upload</v-icon>
                        </v-btn>
                        <v-btn class="right white--text tools-view-height mr-1" color="amarillo" @click="openModalBulk">
                            {{ $t('fulfillment_eon_pendings.bulk_supply') }}
                            <v-icon class="px-1" small>schedule_send</v-icon>
                        </v-btn>
                        <v-btn class="right white--text tools-view-height" color="amarillo" @click="modalNewOrder = true;">
                            {{ $t('fulfillment_eon_pendings.new_order') }}
                            <v-icon class="px-1" small>add</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandlerV2(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalSupply" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillment_eon_pendings.order_supply') }}: {{ this.orderToShow.uon }}
                            </h4>
                        </v-row>
                    </v-card-title>

                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_pendings.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.order_number") }}</label> <label>{{ this.orderToShow.unique_order_number }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shipping_service }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.date") }}</label> <label>{{ this.orderToShow.created_at }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.customer") }}</label> <label>{{ this.orderToShow.customer }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.buyer") }}</label> <label>{{ this.orderToShow.buyer }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_pendings.products') }}</label></div>
                    <span>
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>
                    
                    <div class="marker1-1 mt-3" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_prepaid_pendings.details') }}</label></div>
                    <v-row class="px-7 py-0 mt-3" justify="center" align="center">
                        <v-col cols="12" class="py-0">
                            <v-autocomplete class="mt-0 py-0" v-model="bulk.box" :items="boxes" item-value="id" item-text="name" :label="$t('fulfillments_prepaid_pendings.box')" :rules="rules_required"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="px-7 py-0 mt-2" justify="center" align="center">
                        <v-col cols="12" class="py-0">
                            <v-text-field class="py-0" v-model="bulk.quantity" :label="$t('fulfillments_prepaid_pendings.boxes_per_order')" :rules="rules_required"></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalSupply = false">{{ $t('fulfillment_eon_pendings.go_back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="orderSupply(orderToShow)">
                                {{ $t('fulfillment_eon_pendings.supply') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('fulfillment_eon_pendings.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_eon_pendings.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('fulfillment_eon_pendings.no') }}</v-btn>
                            <v-btn color="error" @click="deleteOrder">
                                {{ $t('fulfillment_eon_pendings.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalBulk" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillment_eon_pendings.fill_orders') }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_pendings.orders') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ this.selected.length }} {{ $t("fulfillment_eon_pendings.orders_to_fill") }}</label></div>
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_pendings.select_box') }}</label></div>

                    <v-row class="px-7 py-0 mt-4" justify="center" align="center">
                        <v-col cols="12" class="py-0">
                            <v-autocomplete class="mt-0 py-0" v-model="bulk.box" :items="boxes" item-value="id" item-text="name" :label="$t('fulfillment_eon_pendings.box')" :rules="rules_required"></v-autocomplete>
                        </v-col>
                    </v-row>
                    
                    <v-row class="px-7 py-0 mt-4" justify="center" align="center">
                        <v-col cols="12" class="py-0">
                            <v-text-field class="py-0" v-model="bulk.quantity" :label="$t('fulfillment_eon_pendings.boxes_per_order')" :rules="numeric_required"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalBulk = false">{{ $t('fulfillment_eon_pendings.go_back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="bulkSupply">
                                {{ $t('fulfillment_eon_pendings.supply') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalBulkResponses" persistent max-width="90%">
                <v-card>
                    <v-card-text>
                        <v-card flat class="mb-5">
                            <v-card-text>
                                <p>{{bulkProps.items.length}} {{ $t('fulfillment_eon_pendings.results') }}</p>
                            </v-card-text>
                            <v-simple-table v-if="bulkProps.items.length > 0" fixed-header height="300px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th v-for="(th, key) in ['order', 'message']" :key="key" class="text-left">{{ th }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, i) in bulkProps.items" :key="i">
                                            <td>{{ item.order }}</td>
                                            <td>{{ item.message }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>

                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn color="amarillo" class="white--text" @click="modalBulkResponses = false; index();">
                                {{ $t('upload_annex.go_back') }}
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillment_eon_pendings.order_details.header') }} {{ this.orderToShow.unique_order_number }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_pendings.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.order_number") }}</label> <label>{{ this.orderToShow.unique_order_number }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shipping_service }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.date") }}</label> <label>{{ this.orderToShow.created_at }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.customer") }}</label> <label>{{ this.orderToShow.customer }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_pendings.buyer") }}</label> <label>{{ this.orderToShow.buyer }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_pendings.products') }}</label></div>
                    <span>
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('fulfillment_eon_pendings.go_back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="orderSupply(orderToShow)">
                                {{ $t('fulfillment_eon_pendings.supply') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalAlreadyOpen" persistent max-width="90%">
                <v-card >
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h3>{{ $t('fulfillment_eon_pendings.page_already_open_1') }} Fullfillment EON {{ $t('fulfillment_eon_pendings.page_already_open_2') }}</h3>
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalNewOrder" persistent max-width="90%">
                <v-card style="min-width: 650px !important">
                    <v-card-text>
                        <v-row align="center" justify="center" class="pt-3 mt-3 text-center">
                            <v-col cols="12">
                                <h3>{{ $t('fulfillment_eon_pendings.new_order') }}</h3>
                            </v-col>
                        </v-row>
                        <v-row class="pb-0 pt-3 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <client-selector :addAllOption="false" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-autocomplete class="mt-0 py-0" v-model="shipment.shipping_service" :items="parcels" :label="$t('fulfillment_eon_pendings.select_parcel')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-autocomplete class="mt-0 py-0" v-model="shipment.channel" :items="channels" item-value="value" item-text="text" :label="$t('fulfillment_eon_pendings.select_channel')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="shipment.unique_order_number" :label="$t('fulfillment_eon_pendings.unique_order_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="shipment.tracking_number" :label="$t('fulfillment_eon_pendings.tracking_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="shipment.consignee_name" :label="$t('fulfillment_eon_pendings.buyers_name')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" multiple :clearable="true" v-model="shipment.label" accept="*" :label="$t('fulfillment_eon_pendings.label')"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" multiple :clearable="true" v-model="shipment.attached_files" accept="*" :label="$t('fulfillment_eon_pendings.attached_files')"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="shipment.total_price" :label="$t('fulfillment_eon_pendings.total_price')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="4" class="py-0 my-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.sku" item-text="sku" item-value="sku" :items="products" :label="$t('fulfillment_eon_pendings.select_product')"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0 my-0 mx-0 px-1">
                                <v-text-field class="py-0" v-model="product.quantity" :label="$t('fulfillment_eon_pendings.quantity')"></v-text-field>
                            </v-col>
                            <v-col cols="3" class="py-0 my-0 mx-0 px-1">
                                <v-text-field class="py-0" v-model="product.unit_price" :label="$t('fulfillment_eon_pendings.unit_price')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0 mx-0 px-0" justify="end" align="end">
                                <v-btn color="amarillo" class="white--text" @click="addProduct()">
                                    {{ $t('fulfillment_eon_pendings.add') }}
                                </v-btn>
                            </v-col>    
                        </v-row>
                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0 mx-0 px-0">
                                <datatable :income_editable="true" class="custom_table_class" :props="{headers:productsHeaders, items: shipment.products}" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                            <v-col cols="11" class="py-0 my-0 mx-0 px-0" align="end" justify="end">
                                <v-btn class="mx-3" text @click="cancelNewOrder()">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                                <v-btn color="amarillo" class="white--text" @click="saveNewOrder()">
                                    {{ $t('fulfillment_eon_pendings.save') }}
                                </v-btn>    
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalImport" persistent max-width="90%" >
                <v-card style="min-width: 650px !important">
                    <v-card-title class="justify-center">
                        {{ $t('fulfillment_eon_pendings.import_orders') }}
                    </v-card-title>
                    <v-stepper v-model="stepperImport">
                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="10">
                                            <v-row class="pb-0 pt-0 my-0" justify="center" align="center">
                                                <v-col cols="10" class="py-0 my-0">
                                                    <client-selector :addAllOption="false" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>
                                                </v-col>
                                            </v-row>
                                            <v-row class="py-0 my-0" justify="center" align="center">
                                                <v-col cols="10" class="py-0 my-0">
                                                    <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="true" v-model="importOrders.file" accept=".csv" :rules="rules_required" :label="$t('fulfillment_eon_pendings.shipments_file')"></v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row class="py-0 my-0" justify="center" align="center">
                                                <v-col cols="10" class="py-0 my-0">
                                                    <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="true" v-model="importOrders.labels" accept=".zip" :rules="rules_required" :label="$t('fulfillment_eon_pendings.labels_file')"></v-file-input>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepperImport = 3">
                                                        <v-icon class="material-icons-outlined" aria-hidden="false" color="white" small>
                                                            help
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('fulfillments_prepaid_returns.help') }}</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card-text> 
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="11" class="py-0 my-0 mx-0 px-0" align="end" justify="end">
                                        <v-btn class="mx-3" text @click="modalImport = false">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                                        <v-btn color="amarillo" class="white--text" @click="uploadOrders()">
                                            {{ $t('fulfillment_eon_pendings.save') }}
                                        </v-btn>    
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-simple-table v-if="this.responses.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillments_prepaid_returns.order') }}</th>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillments_prepaid_returns.message') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in responses" :key="i">
                                                    <td>{{ item.order }}</td>
                                                    <td>{{ item.message }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-btn class="px-3" text @click="stepperImport = 1; modalImport = false;">{{ $t('fulfillments_prepaid_returns.close') }}</v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                    <p class="py-0 my-0">Archivo de ordenes</p> 

                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- En caso de que la orden contenga dos o más SKUs diferentes, debe separar cada uno de ellos con el caracter pipe ( | ) en la columna correspondiente, lo mismo aplicará para la columna donde se indica la cantidad de productos.</p> 
                                    <p class="py-0 my-0">4.- El archivo debe ser formato CSV.</p> 
                                    <p class="py-0 my-0">5.- Los archivos contenidos en el .zip de las etiquetas deben tener por nombre el numero de orden al que pertenecen.</p> 
                                    <p class="py-0 my-0">6.- El archivo .zip debe conterner todos los archivos de etiqueta sin carpetas.</p> 
                                    <p class="py-0 my-0">7.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                    <p class="py-0 my-0">8.- Ingresar uno de los valores permitidos para la columna canal (escrito como se muestra).</p> 

                                    <p class="py-0 my-0">Canales permitidos: AMAZON, CLARO SHOP, MERCADO LIBRE, MOSTRADOR, LIVERPOOL, SHOPIFY, WOOCOMERCE, WALMART</p> 
                                
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                   <th class="text-left" style="padding: 0 5px !important;">orden</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">paqueteria</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">guia</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">cliente</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">sku</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">cantidad</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">canal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                  <td>OrdenPrueba001</td>
                                                    <td>FEDEX</td>
                                                    <td>4889312354</td>
                                                    <td>Josúe René Galindo</td>
                                                    <td>THISISSKU01|THISISSKU2</td>
                                                    <td>2|1</td>
                                                    <td>MERCADO LIBRE</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    
                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepperImport = 1">
                                            {{ $t('products.go_back') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                    <div class="marker1"></div>

                </v-card>
            </v-dialog>
        </v-container>   
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
	computed: {
        headers(){
            return [
                { text: this.$t('fulfillment_eon_pendings.order'), value: 'uniqueOrderNumber' },
                { text: this.$t('fulfillment_eon_pendings.customer'), value: 'client.name' },
                { text: this.$t('fulfillment_eon_pendings.date'), value: 'createdAt' },
                { text: this.$t('fulfillment_eon_pendings.time'), value: 'time' },
                { text: this.$t('fulfillment_eon_pendings.shipping_service'), value: 'shippingService' },
                { text: this.$t('fulfillment_eon_pendings.actions'), value: 'actions'},
            ];
        },
        headersBulk(){
            return [
                { text: this.$t('fulfillment_eon_pendings.order'), value: 'order' },
                { text: this.$t('fulfillment_eon_pendings.status'), value: 'status' },
                { text: this.$t('fulfillment_eon_pendings.message'), value: 'message' }
            ];
        },
        productsHeaders(){
            return [
                { text: this.$t('fulfillment_eon_pendings.sku'), value: 'sku', width: '60%' },
                { text: this.$t('fulfillment_eon_pendings.quantity'), value: 'quantity', width: '20%' },
                { text: this.$t('fulfillment_eon_pendings.unit_price'), value: 'unit_price', width: '20%' },
                { text: '', value: 'actions', class: 'text-uppercase', width:'10%' }
            ];
        },  
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            sending_data: false,
            props: {
                search: '',
                items: [
                ],
            },
            stepperImport: 1,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            responses: [],
            selected: [],
            modalDelete: false,
            modalDetail: false,
            modalBulk: false,
            modalAlreadyOpen: false,
            modalBulkResponses: false,
            modalNewOrder: false,
            modalSupply: false,
            modalUpdate: false,
            modalImport: false,
            orderToUpdate: '',
            orderToDelete: '',
            orderToShow: '',
            importOrders: {
                file: null,
                labels: null,
                client_id: ""
            },
            actions:[
                {
                    name: this.$t('fulfillment_eon_pendings.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillment_eon_pendings.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    method: 'askUpdate',
                },
                {
                    name: this.$t('fulfillment_eon_pendings.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                },
                {
                    name: this.$t('fulfillment_eon_pendings.supply'),
                    icon: {
                        color: 'accent',
                        icon: 'send'
                    },
                    method: 'supplyOrderModal',
                }
            ],
            actions_p: [
                {
                    name: this.$t('edit_shipment.delete_product'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteProduct',
                }
            ],
            shipment: {
                client_id: "",
                warehouse_id: this.$store.state.warehouseId,
                unique_order_number: "",
                shipping_service: "",
                tracking_number: "",
                channel: "",
                consignee_name: "",
                label: [],
                attached_files: [],
                products: [],
                total_price: ""
            },
            bulkProps: {
                items: [],
                search: ""
            },
            parcels: [
                "99MINUTOS", "AMAZON", "COPPEL", "DHL", "ESTAFETA", "FEDEX", "HERMES", "JTEXPRESS", "MERCADO COLECTA", "MERQ", "PAQUETEXPRESS", "QUIKEN",  "UPS" , "YEGO"
            ],
            channels: [
                { text: "AMAZON", value: "EON:amazon" },
                { text: "CLARO SHOP", value: "EON:ClaroShop" },
                { text: "COPPEL", value: "EON:coppel" },
                { text: "MERCADO LIBRE", value: "EON:meli" },
                { text: "MOSTRADOR", value: "EON:Mostrador" },
                { text: "LIVERPOOL", value: "EON:LiverpoolEL" },
                { text: "SHOPIFY", value: "EON:Shopify" },
                { text: "WOOCOMERCE", value: "EON:WooCommerce" },
                { text: "WALMART", value: "EON:WalmartEL" },
            ],
            customers: [],
            products: [],
            product: {
                sku: "",
                quantity: "",
                unit_price: ""
            },
            bulk: {
                box: "",
                quantity: ""
            },
            boxes: [],
            selected_box: ""
        }
    },
    async mounted(){
        this.$store.state.module = "headers.fulfillment_eon_pendings";
        await this.checkIfCanOpen("EON");
        
        if(this.modalAlreadyOpen == false) this.index();
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        methodHandlerV2(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.selected = [];
            this.boxes = [];
            this.modalBulk = false;
            this.modalBulkResponses = false;
            this.modalSupply = false;
            
            this.$http.get(this.$store.state.chronosApi + 'boxes', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x = x.attributes;
                    this.boxes.push(x)
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_orders?status=PLACED', {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    x = x.attributes;
                    x.time = x.createdAt.substring(11,16);
                    x.createdAt = x.createdAt.substring(0,10);
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x['actions'][1].link = "/external_shipments/edit_pending/" + x.id
                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.orderToShow = {
                    unique_order_number: response.data.order.unique_order_number,
                    created_at: response.data.order.created_at.substring(0,10),
                    shipping_service: response.data.order.shipping_service,
                    customer: response.data.order.name,
                    buyer: response.data.order.consignee_name,
                    products: JSON.parse(response.data.order.products).products
                }
                this.$store.state.loaderProps.visible = false;
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        supplyOrderModal(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.orderToShow = {
                    unique_order_number: response.data.order.unique_order_number,
                    created_at: response.data.order.created_at.substring(0,10),
                    shipping_service: response.data.order.shipping_service,
                    customer: response.data.order.name,
                    buyer: response.data.order.consignee_name,
                    client_id: response.data.order.client_id,
                    products: JSON.parse(response.data.order.products).products
                }
                this.$store.state.loaderProps.visible = false;
                this.modalSupply = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        orderSupply: async function(order){
            if(this.sending_data) return;
            this.sending_data = true;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const shipment = {
                "external_shipments" : [
                    {
                        "unique_order_number": order.unique_order_number,
                        "client_id": order.client_id,
                        "boxes": {
                            "box_id": parseInt(this.bulk.box),
                            "quantity": parseInt(this.bulk.quantity)
                        }
                    }
                ]
            }

            this.$http.post(this.$store.state.chronosApi + 'external_shipment/process_orders', shipment, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_pendings.filled')
                }
                this.modalDetail = false;
                this.modalSupply = false;
                this.index();
            }).catch((err) => {
                console.log(err);
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.filled_error')
                }
                this.index();
            }).finally(() => {
                this.sending_data = false;
                this.$store.state.loaderProps.visible = false;
            })
        },
        askDelete(order){
            console.log(order.id)
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'external_shipment/delete/' + this.orderToDelete.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_pendings.deleted')
                }
                this.modalDelete = false;
                this.index();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        bulkSupply: async function(){
            this.bulkProps.items = [];
            if(this.sending_data) return;
            this.sending_data = true;

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            var orders = [];
            this.selected.forEach(x => {
                orders.push({
                    "unique_order_number": x.uniqueOrderNumber,
                    "client_id": x.client.id,
                    "boxes": {
                        "box_id": parseInt(this.bulk.box),
                        "quantity": parseInt(this.bulk.quantity)
                    }
                });
            });

            const shipments = {
                "external_shipments" : orders
            }
                
            this.sendOrders(shipments, lsToken);
        },
        sendOrders(shipments, lsToken){
            this.$http.post(this.$store.state.chronosApi + 'external_shipment/process_orders', shipments, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.forEach(x => {
                    this.bulkProps.items.push({
                        "order": x.unique_order_number,
                        "message": x.message ?? "Success!"
                    });
                })
                this.modalDetail = false;
                this.modalSupply = false;
                this.modalBulkResponses = true;
            }).catch((err) => {
                console.log(err);
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.filled_error')
                }
                this.index();
            }).finally(() => {
                this.sending_data = false;
                this.$store.state.loaderProps.visible = false;
            })
        },
        checkIfCanOpen(parcel){
            localStorage.open_pages = Date.now();
            window.addEventListener('storage', (e) => {
                if(e.key == "open_pages") localStorage.setItem("page_available_cod_" + parcel, Date.now())
                if(e.key == "page_available_cod_" + parcel) this.modalAlreadyOpen = true;              
            }, false);
        },
        saveNewOrder(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            
            if(this.shipment.attached_files.length == 0 && this.shipment.label.length == 0){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.label_and_attached_files_missing')
                }

                this.$store.state.loaderProps.visible = false;
                return;
            }

            const formData = new FormData();
            formData.append('warehouse_id', this.shipment.warehouse_id);
            formData.append('unique_order_number', this.shipment.unique_order_number);
            formData.append('shipping_service', this.shipment.shipping_service);
            formData.append('tracking_number', this.shipment.tracking_number);
            formData.append('consignee_name', this.shipment.consignee_name);
            formData.append('client_id', this.shipment.client_id);
            formData.append('skip_client', true);
            formData.append('total', parseFloat(this.shipment.total_price));
            formData.append('channel', this.shipment.channel);

            var c_products = []
            this.shipment.products.forEach(x => {
                c_products.push({
                    sku: x.sku,
                    quantity: parseInt(x.quantity),
                    unit_price: x.unit_price ?? "",
                });
            })

            formData.append('products', JSON.stringify({products: c_products}));

            if(this.shipment.label.length != 0) {
                this.shipment.label.forEach(x => {
                    formData.append('label[]', x);
                })
            }
            
            if(this.shipment.attached_files.length != 0){
                this.shipment.attached_files.forEach( x => {
                    formData.append('attached_files[]', x);
                })
            }

            // console.log([...formData]) // Print form data displaying all pr

            this.$http.post(this.$store.state.chronosApi + 'external_shipment/create', formData, {headers: { Authorization: lsToken } })
            .then((response) => {
                response;
                this.modalNewOrder = false;
                this.shipment = {
                    client_id: "",
                    warehouse_id: this.$store.state.warehouseId,
                    unique_order_number: "",
                    shipping_service: "",
                    tracking_number: "",
                    consignee_name: "",
                    label: [],
                    attached_files: [],
                    products: []
                }
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_pendings.created')
                }
                this.index();
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.error')
                }
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        addProduct(){
            if(this.product.sku != "" && this.product.quantity != ""){
                const product = {
                    sku: this.product.sku,
                    quantity: this.product.quantity,
                    unit_price: this.product.unit_price,
                    actions: JSON.parse(JSON.stringify(this.actions_p)),
                    parameters: this.product.sku
                }  

                this.shipment.products.push(product);
                this.product.sku = "";
                this.product.quantity = "";
            }
        }, 
        deleteProduct(sku){
            const index = this.shipment.products.findIndex(x => x.sku === sku);
            this.shipment.products.splice(index, 1);
        },
        cancelNewOrder(){
            this.modalNewOrder = false;

            this.shipment = {
                client_id: "",
                warehouse_id: this.$store.state.warehouseId,
                unique_order_number: "",
                shipping_service: "",
                tracking_number: "",
                consignee_name: "",
                label: [],
                attached_files: [],
                products: []
            }
        },
        openModalBulk(){
            if(this.selected.length > 0){
                this.modalBulk = true;
                this.bulk.uons = this.selected;
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillments_prepaid_pendings.select_empty')
                }
            }
        },
        uploadOrders(){
            if(this.importOrders.client_id != "" && this.importOrders.file != null && this.importOrders.labels != null){
                const lsToken = localStorage.getItem("access_token");
                this.$store.state.loaderProps.visible = true;
                const formData = new FormData();
                formData.append('client_id', this.importOrders.client_id);
                formData.append('file', this.importOrders.file);
                formData.append('labels', this.importOrders.labels);

                this.$http.post(this.$store.state.chronosApi + 'external_shipment/create/bulk', formData, {headers: { Authorization: lsToken } })
                .then((response) => {
                    this.responses = response.data;
                    this.stepperImport = 2
                    this.importOrders = {
                        client_id: "",
                        file: [],
                        labels: []
                    }
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            } else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.all_fields_are_required')
                }
            }
        }
    },
    watch:{
        '$store.state.clientId': function (){
            this.shipment.client_id = this.$store.state.clientId;
            this.importOrders.client_id = this.$store.state.clientId;

            const lsToken = localStorage.getItem("access_token");
            this.products = [];

            this.$http.get(this.$store.state.chronosApi + 'productsAndKitsByClient/' + this.shipment.client_id, {headers: { Authorization: lsToken } })
            .then((response1) => {
                response1.data = response1.data.data;
                response1.data.forEach( x => {
                    this.products.push(x);
                })
            })
        },
    }
}
</script>

<style lang="scss">
</style>