<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1">
                <v-col sm="6" md="6" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillment_eon_send.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <!-- <v-spacer></v-spacer>  -->
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillment_eon_send.modal.delete') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_eon_send.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('fulfillment_eon_send.no') }}</v-btn>
                            <v-btn color="error" @click="deleteOrder">
                                {{ $t('fulfillment_eon_send.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillment_eon_send.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>

                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_send.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.order_number") }}</label> <label>{{ this.orderToShow.unique_order_number }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.shipping_service") }}</label> <label>{{ this.orderToShow.shipping_service }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.date") }}</label> <label>{{ this.orderToShow.created_at }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.customer") }}</label> <label>{{ this.orderToShow.customer }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_send.buyer") }}</label> <label>{{ this.orderToShow.buyer }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_send.products') }}</label></div>
                    <span>
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>
                
                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn  color="amarillo" class="white--text" @click="modalDetail = false">
                                {{ $t('fulfillment_eon_send.go_back') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalRestore" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillment_eon_send.restore_modal.restore') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_eon_send.restore_modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalRestore = false">{{ $t('fulfillment_eon_send.no') }}</v-btn>
                            <v-btn color="error" @click="restoreOrder">
                                {{ $t('fulfillment_eon_send.restore_yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
        </v-container>        
    </div>
</template>

<script>
export default {
	computed: {
        headers(){
            return [
                { text: this.$t('fulfillment_eon_send.order'), value: 'uniqueOrderNumber' },
                { text: this.$t('fulfillment_eon_send.customer'), value: 'client.name' },
                { text: this.$t('fulfillment_eon_send.shipping_service'), value: 'shippingService' },
                { text: this.$t('fulfillment_eon_send.pickup'), value: 'pickupDate' },
                { text: this.$t('fulfillment_eon_send.last_status'), value: 'lastStatus.status' },
                { text: this.$t('fulfillment_eon_send.actions'), value: 'actions' },
            ];
        
        }
    },
    data(){
        return {
            props: {
                search: '',
                items: [],
            },
            selected: [],
            modalDelete: false,
            modalDetail: false,
            modalRestore: false, 
            orderToDelete: '',
            orderToShow: '',
            orderToRestore: '',
            actions:[
                {
                    name: this.$t('fulfillment_eon_send.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillment_eon_send.restore'),
                    icon: {
                        color: 'accent',
                        icon: 'history'
                    },
                    method: 'askRestore',
                },
                {
                    name: this.$t('fulfillment_eon_send.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                }
            ]
        }
    },
    mounted(){
        this.$store.state.module = "headers.fulfillment_eon_sent";
        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_orders?status=SEND', {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    x = x.attributes;
                    x.date = x.createdAt.substring(0,10);
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    
                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.orderToShow = {
                    unique_order_number: response.data.order.unique_order_number,
                    created_at: response.data.order.created_at.substring(0,10),
                    shipping_service: response.data.order.shipping_service,
                    customer: response.data.order.name,
                    buyer: response.data.order.consignee_name,
                    products: JSON.parse(response.data.order.products).products
                }
                this.$store.state.loaderProps.visible = false;
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        askRestore(order){
            this.modalRestore = true;
            this.orderToRestore = order;
        },
        restoreOrder(){
            this.$store.state.loaderProps.visible = true;
            if(this.orderToRestore != ''){
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'external_shipment/restore/' + this.orderToRestore.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200) {
                        this.orderToRestore = '';
                        this.modalRestore = false;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('fulfillments_cod_sent.restored')
                        }
                        this.index();
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: "error"
                }
            }
        },
        askDelete(order){
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'external_shipment/delete/' + this.orderToDelete.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_pendings.deleted')
                }
                this.modalDelete = false;
                this.index();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        }
    },
    watch:{
    }
}
</script>

<style lang="scss">
    .marker1{
        width: 100%;
        background-color: #c1d500;
        height: 12px;
        bottom: 0px;
    }
    .marker1-1{
        width: 90%;
        margin:auto;
        background-color: #c1d500;
        height: 20px;
    }
    .detail_content{
        width: 90%;
        margin: auto;
        border-bottom: 1px solid black;
        padding: 0 .35em;
        display: flex;
        justify-content: space-between;
    }
    .detail_content1{
        width: 90%;
        margin: auto;
        border-bottom: 1px solid black;
        padding: 0 .35em;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
    }
</style>