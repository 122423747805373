import Vue from "vue";
import "./components/";
import axios from "axios";
import i18n from "./i18n";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

router.beforeEach((to, from, next) => {
  const lsToken = localStorage.getItem("access_token");
  if (lsToken !== null) {
    axios.get(store.state.chronosApi + "current-user", {
        headers: { Authorization: lsToken },
      })
      .then((response) => {
        if (!response.data.resource) {
          store.state.loggedIn = false;
        } else {
          store.state.loggedIn = true;
          if(response.data.resource.data.attributes.image != null) store.state.loggedPicture = response.data.resource.data.attributes.image;

          store.state.loggedUsername = response.data.resource.data.attributes.name + ' ' + response.data.resource.data.attributes.paternal_surname + ' ' + response.data.resource.data.attributes.maternal_surname;
          store.state.loggedUserRole = [];
          response.data.resource.data.attributes.roles.forEach( x => {
              store.state.loggedUserRole.push({
                  id: x.attributes.id,
                  name: x.attributes.name
              })
          })
          store.state.loggedUserEmail = response.data.resource.data.attributes.email;
          store.state.loggedUserPermissions = [];
          response.data.resource.data.attributes.roles[0].attributes.permissions.forEach( x => {
              store.state.loggedUserPermissions.push(x.attributes.name);
          });
          store.state.clientUserId = response.data.resource.data.attributes.client_id;
          if(response.data.resource.data.attributes.client != null && response.data.resource.data.attributes.client != undefined)
            store.state.clientUserIsCod = (response.data.resource.data.attributes.client.attributes.fulfillment == "cod" || response.data.resource.data.attributes.client.attributes.fulfillment == 'all') ? true : false;        
          store.state.clientShippingServices = response.data.resource.data.attributes.shipping_services;
          store.state.warehouseId = response.data.resource.data.attributes.warehouse.attributes.id;
          localStorage.setItem('warehouse_id', store.state.warehouseId);
          localStorage.setItem('warehouseName', response.data.resource.data.attributes.warehouse.attributes.name);
        }
        if (store.state.loggedIn) {
          if (store.state.clientUserId != null) {
            store.state.layout = "customerDashboard";
            document.title = to.meta.title;
            // next({ name: 'customerDashboard' })
            next();
          }
          else{
            store.state.layout = "dashboard";
            document.title = to.meta.title;
            next();
          }
        } else {
          if (to.meta.auth) {
            store.state.layout = "login";
            document.title = "Chronos - Inicio de sesión";
            next("login");
          } else {
            document.title = "Chronos - Inicio de sesión";
            store.state.layout = "login";
            next();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        /* eslint-disable */
        // if (error.response.status == 401 || error.response.status == 500) {
          localStorage.removeItem("access_token");
          document.title = "Chronos - Inicio de sesión";
          store.state.layout = "login";
          next("login");
        // }
      });
  } else {
    if (to.meta.auth) {
      store.state.layout = "login";
      next("login");
    } else {
      store.state.layout = "login";
      next();
    }
  }
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
