<template>
    <div>
        <v-data-table class="px-0 my-2"
        
        no-results-text="No hay resultados"
            :headers="props.headers"
            :items="props.items"
            :search="props.search"
            :show-select="showSelect"
            v-model="content"
            calculate-widths
            :footer-props="{
                'items-per-page-text': this.$t('datatable.shown'),
                showFirstLastPage: false,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                'show-current-page': true,
                'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP
            }"
            :page="typeof this.page === 'undefined' ? 1 : page"
            :mobile-breakpoint="0"
            :hide-default-footer="hide_pagination"
        >
            <template v-for="custom_column in custom_columns" v-slot:[`item.${custom_column}`]="{ item }">
                <slot :name="`item.${custom_column}`" v-bind:item="item"></slot>
            </template>

            <template v-slot:item._actions="{ item }">
                <slot name="other-actions" v-bind:item="item"></slot>
            </template>

            <!-- Editable fields  "Quantity" -->
            <template v-slot:item.quantity="{ item, header, index}" v-if="income_editable == true || outcome_editable == true">
                <v-edit-dialog
                    :return-value.sync="editQuantity"
                    @save="save(item, 'quantity', index)"
                    @cancel="cancel"
                    @open="open(item, 'quantity')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editQuantity"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields  "unit_price" -->
            <template v-slot:item.unit_price="{ item, header, index}" v-if="unit_price_editable == true">
                <v-edit-dialog
                    :return-value.sync="editUnitPrice"
                    @save="save(item, 'unit_price', index)"
                    @cancel="cancel"
                    @open="open(item, 'unit_price')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editUnitPrice"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            
            <!-- Editable fields  "Location" -->
            <template v-slot:item.location="{ item, header, index}" v-if="income_editable == true">
                <v-edit-dialog
                    :return-value.sync="editLocation"
                    @save="save(item, 'location', index)"
                    @cancel="cancel"
                    @open="open(item, 'location')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                        <v-autocomplete :load="locations_load" class="mt-0" v-model="editLocation" item-value="id" item-text="name" :items="locations" :label="$t('edit_appointment.product_details.select_location')"></v-autocomplete>
                    </template>
                </v-edit-dialog>
            </template>
            
            <!-- Editable fields  "pallet" -->
            <template v-slot:item.pallet="{item, header, index}" v-if="income_editable == true">
                <v-edit-dialog
                    :return-value.sync="editPallet"
                    @save="save(item, 'pallet', index)"
                    @cancel="cancel"
                    @open="open(item, 'pallet')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editPallet"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            
            <!-- Editable fields  "Box" -->
            <template v-slot:item.box="{item, header, index}" v-if="income_editable == true">
                <v-edit-dialog
                    :return-value.sync="editBox"
                    @save="save(item, 'box', index)"
                    @cancel="cancel"
                    @open="open(item, 'box')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editBox"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields  "Batch" -->
            <template v-slot:item.batch="{item, header, index}" v-if="income_editable == true">
                <v-edit-dialog
                    :return-value.sync="editBatch"
                    @save="save(item, 'batch', index)"
                    @cancel="cancel"
                    @open="open(item, 'batch')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        v-model="editBatch"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields  "Expiration" -->
            <template v-slot:item.expiration="{item, header, index}" v-if="income_editable == true">
                <v-edit-dialog
                    :return-value.sync="editExpiration"
                    @save="save(item, 'expiration', index)"
                    @cancel="cancel"
                    @open="open(item, 'expiration')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        v-model="editExpiration"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields  "damaged_product" -->
            <template v-slot:item.damaged_product="{item, header, index}" v-if="income_editable == true">
                <v-edit-dialog
                    :return-value.sync="editDamageProduct"
                    @save="save(item, 'damaged_product', index)"
                    @cancel="cancel"
                    @open="open(item, 'damaged_product')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editDamageProduct"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- Editable fields  "stolen_product" -->
            <template v-slot:item.stolen_product="{item, header, index}" v-if="income_editable == true">
                <v-edit-dialog
                    :return-value.sync="editStolenProduct"
                    @save="save(item, 'stolen_product', index)"
                    @cancel="cancel"
                    @open="open(item, 'stolen_product')"
                    @close="close"
                    large
                    :cancel-text="$t('datatable.cancel')"
                    :save-text="$t('datatable.save')"
                > {{item[header.value]}}
                    <template v-slot:input>
                    <v-text-field
                        :rules="numeric_required"
                        v-model="editStolenProduct"
                        label="Edit"
                        single-line
                    ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>

            <!-- ACTIONS -->
            <template v-slot:item.actions="{ item }">
                <v-tooltip top v-for="(action, i) of item.actions" :key="i" v-permission:any="action.permission">                    
                    
                    <!-- Links Filled -->
                    <template v-if="action.name == $t('pendings.files') || action.name == $t('processed.files') || action.name == $t('no_annex.files') || action.name == $t('digital_heritage.files')" v-slot:activator="{ on }">
                        <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                        <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>    
                    </template>

                    <!-- Links Outlined -->
                    <template v-slot:activator="{ on }" v-else-if="action.name != $t('end_process.already_checked') && action.name != $t('edit_appointment.product_details.checked') && action.name != $t('edit_appointment.product_details.unchecked') && action.name != $t('end_process.already_checked_with_incidence') && action.name != $t('end_process.already_exist_incidence') && action.name != $t('pendings.files') && action.name != $t('processed.files') && action.name != $t('no_annex.files')  && action.name != $t('digital_heritage.files')">
                        <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                            <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                        <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                            <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                    </template>

                    <!-- Buttons -->
                    <template v-else-if="action.name == $t('end_process.already_checked') || action.name == $t('edit_appointment.product_details.checked') || action.name == $t('edit_appointment.product_details.unchecked') || action.name == $t('end_process.already_checked_with_incidence') || action.name == $t('end_process.already_exist_incidence') || action.name == $t('edit_location.disable') || action.name == $t('edit_location.disabled')" v-slot:activator="{ on }">
                        <v-icon  small :disabled="!action.value" v-on="on" :color="action.icon.color">{{ action.icon.icon }}</v-icon>
                    </template>

                    <!-- RFID -->
                    <template v-else-if="action.name == 'rfid'" v-slot:activator="{ on }">
                        <v-icon @click="methodHandler(action.method, item.parameters)" small  v-on="on" :color="action.icon.color">{{ action.icon.icon }}</v-icon>
                    </template>
                    <span>{{ action.name }}</span>
                </v-tooltip>
            </template>
            
            <!-- Colored observation in stuck orders and stuck external orders -->
            <template v-slot:item.observation="{ item }"> 
                <td style="text-align: center; text-transform: uppercase;">
                    <span :class="'back_orange'"> {{ item.observation }} </span> 
                </td>
            </template>

            <!-- Colored departure time for visits and attendances -->
            <template v-slot:item.departure_time="{ item }"> 
                <td style="text-align: center; text-transform: uppercase;">
                    <span v-if="item.departure_time == '23:59'" style="color: red"> {{ item.departure_time }} </span> 
                    <span v-else> {{ item.departure_time }} </span> 
                </td>
            </template>

            <template v-slot:item.exit_time="{ item }"> 
                <td style="text-align: center; text-transform: uppercase;">
                    <span v-if="item.exit_time == '23:59'" style="color: red"> {{ item.exit_time }} </span> 
                    <span v-else> {{ item.exit_time }} </span> 
                </td>
            </template>


            <!-- Id dot in appointment id. INDEX -->
            <template v-slot:item.idIndex="{ item }"> 
                <v-tooltip top >
                    <template v-slot:activator="{ on }">
                        <td>
                            <v-icon small v-on="on" :color="item.dotColor"> fiber_manual_record </v-icon>
                            <!-- <v-icon v-if="item.dotColor == 'red'"  small :color="item.dotColor"> inventory_2 </v-icon>  -->
                            <!-- <v-icon v-if="item.dotColor == 'red'"  small :color="item.dotColor"> fiber_manual_record </v-icon> 
                            <v-icon v-if="item.dotColor == 'orange' || item.dotColor == 'blue_dashboard'" small :color="item.dotColor"> star </v-icon> 
                            -->
                            {{ item.idIndex }} 
                        </td>
                    </template>

                    <span>{{$t('datatable.' + item.dotColor)}}</span>
                </v-tooltip>
            </template>

            <!-- tooltip for actions instead button -->
            <template v-slot:item.checked="{ item }">
                <v-tooltip top v-for="(action, i) of item.checked" :key="i">
                    <template v-slot:activator="{ on }">
                        <v-icon class="material-icons-outlined" small :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                    </template>
                    <span>{{ action.name }}</span>
                </v-tooltip>
            </template>

            <!-- Colored status for orders in customer view -->
            <template v-slot:item.coloredStatus="{ item }"> 
                <td style="text-align: center; text-transform: uppercase;">
                    <span :class="{
                    'back_orange': item.color == 'orange',
                    'back_blue': item.color == 'blue',
                    'back_green': item.color == 'green',
                    'back_purple': item.color == 'purple',
                    'back_red': item.color == 'red',
                }"> {{ item.coloredStatus }} </span> 
                </td>
            </template>

            <v-data-footer :items-per-page-options="[]"></v-data-footer>
        </v-data-table>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>    
</template>
<script>
    export default {
        computed: {
            numeric_required(){
                return [
                    v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                    v => !!v || this.$t('edit_appointment.validations.required_field')
                ];
            }
        },
        props: [
            'props', 
            'page',
            'showSelect',
            'value',
            'custom_columns',
            'income_editable', 
            'unit_price_editable',
            'outcome_editable',
            'hide_pagination'
        ],
        data() {
            return {
                content: this.value,
                pageStart: 1,
                pageStop: 10, 
                editQuantity: "",
                editUnitPrice: "",
                editPallet: "",
                editBox: "",
                editLocation: "",
                editBatch: "",
                editExpiration: "",
                editStolenProduct: "",
                editDamageProduct: "",
                editDescription: "",
                locations: [],
                locations_load: false,
                snackbar: {
                    visible: false,
                    color: 'primary',
                    text: ''
                },
            }
        },
        methods: {
            methodHandler(methodName, parameters){
                console.log("estamos aqui em el handler")
                console.log(methodName, parameters)
                this.$emit('methodHandler', {
                    methodName: methodName, 
                    parameters: parameters
                });
            },
            save(param, column, index) {
                if(this.isInDesiredForm(this.editQuantity) && column == "quantity"){
                    param.quantity = parseInt(this.editQuantity);
                    param.updated = true;
                }
                else if(this.isInDesiredForm(this.editUnitPrice) && column == "unit_price"){
                    param.unit_price = parseFloat(this.editUnitPrice);
                    param.updated = true;
                }
                else if(this.isInDesiredForm(this.editPallet) && column == "pallet") {
                    param.pallet = parseInt(this.editPallet);
                    param.updated = true;
                }
                else if(this.isInDesiredForm(this.editBox) && column == "box") {
                    param.box = parseInt(this.editBox);
                    param.updated = true;
                }
                else if(column == "batch") {
                    this.editBatch = this.editBatch.toUpperCase().replace(/\s/g,'');
                    param.batch = this.editBatch;
                    param.updated = true;
                }
                else if(column == "expiration") {
                    this.editExpiration = this.editExpiration.toUpperCase().replace(/\s/g,'');
                    param.expiration = this.editExpiration;
                    param.updated = true;
                }
                else if(column == 'location'){
                    param.location_id = this.editLocation;
                    param.updated = true;
                    param.index = index;
                }
                else if(this.isInDesiredForm(this.editDamageProduct) && column == "damaged_product") {
                    var damaged = parseInt(this.editDamageProduct);

                    if(damaged + param.stolen_product <= param.totalquantity){
                        param.quantity = param.totalquantity - (param.stolen_product + damaged); 
                        param.damaged_product = damaged;
                        param.updated = true;
                    }
                    else this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t("datatable.quantity_error")
                    }
                }
                else if(this.isInDesiredForm(this.editStolenProduct) && column == "stolen_product") {
                    var stolen = parseInt(this.editStolenProduct);

                    if(stolen + param.damaged_product <= param.totalquantity){
                        param.quantity = param.totalquantity - (param.damaged_product + stolen); 
                        param.stolen_product = stolen;
                        param.updated = true;
                    }
                    else this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t("datatable.quantity_error")
                    }
                }
                else console.log("Not Valid")
            },
            cancel() {},
            open(param, column) {
                if(column == "location"){
                    const lsToken = localStorage.getItem("access_token");
                    this.locations_load = true;

                    if(this.income_editable == true){
                        this.$http.get(this.$store.state.chronosApi + 'getProductLocations/Entrada/' + param.product_id, {headers: {Authorization: lsToken}})
                        .then((response) => {
                            if(response.status === 200) this.locations = response.data;
                            this.locations_load = false;
                        });
                    }
                }
            },
            close() {},
            isInDesiredForm(str) {
                var n = Math.floor(Number(str));
                return n !== Infinity && String(n) === str && n >= 0;
            }
        },
        watch: {
            content: {
                handler(val){
                    this.$emit('input',val);
                },
                deep: true
            },
        }
    }
</script>
<style lang="scss">
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        font-size: 11px !important;
        color: #00587d !important;
        border-bottom: 2px solid #00587d !important;
        border-collapse: collapse !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        font-size: 11px !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > .test,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > .test,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > .test {
        font-size: 11px !important;
        display:inline-flex !important; 
        align-items: center !important;
    }

    .hidden {
       background-color: transparent !important;
       color: transparent !important;
    }
    
    td, th{
        padding:0 8px !important;
    }
</style>