<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 size pb-3">
                <v-tabs v-model="tab" align-with-title backgroundColor="white_highlight" active-class="active_tab">
                    <v-tabs-slider color="amarillo"></v-tabs-slider>

                    <v-tab v-for="item in tab_items" :key="item" class="non_selected_tab">
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <v-card-text class="px-0" v-if="tab != 4">
                    <v-row class="px-7 py-0 ml-1">
                        <v-col class="pt-2 py-0" cols="2">
                            <v-autocomplete class="mt-0" v-model="periodo" :items="periodos" :label="$t('dashboard.period')"></v-autocomplete> 
                        </v-col>
                        <v-col class="pt-2 py-0" cols="2">
                            <v-autocomplete class="mt-0" v-model="anio" :items="anios" :label="$t('dashboard.year')"></v-autocomplete> 
                        </v-col>
                        <v-col class="pt-2 py-0" cols="3" v-if="periodo == $t('dashboard.weekly')">
                            <v-autocomplete class="mt-0" v-model="currentWeek" :items="weeks" :label="$t('dashboard.week')"></v-autocomplete> 
                        </v-col>
                        <v-col class="pt-2 py-0" cols="3" v-if="periodo == $t('dashboard.monthly')">
                            <v-autocomplete class="mt-0" v-model="currentMonth" :items="monthsNames" :label="$t('dashboard.month')"></v-autocomplete> 
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-tabs-items v-model="tab">
                    <!-- Operation -->
                    <v-tab-item>
                        <d-operations 
                        :initial_date="initialDate" 
                        :final_date="finalDate"
                        :totalAppointmentsOptions="totalAppointmentsOptions"
                        :yearChartOptions="yearChartOptions"
                        :colors="colors"
                        :load="tab"
                        ></d-operations>
                    </v-tab-item>

                    <!-- Fulfillment -->
                    <v-tab-item>
                        <d-fulfillment 
                        :initial_date="initialDate" 
                        :final_date="finalDate"
                        :totalAppointmentsOptions="totalAppointmentsOptions"
                        :yearChartOptions="yearChartOptions"
                        :colors="colors"
                        :load="tab"
                        ></d-fulfillment>
                    </v-tab-item>

                    <!-- Supervision -->
                    <v-tab-item>
                        <d-supervision 
                        :initial_date="initialDate" 
                        :final_date="finalDate"
                        :yearChartOptions="sideChartOptions"
                        :colors="colors"
                        :load="tab"
                        ></d-supervision>
                    </v-tab-item>

                    <!-- Audits -->
                    <v-tab-item>
                        <d-audits 
                        :initial_date="initialDate" 
                        :final_date="finalDate"
                        :totalAppointmentsOptions="multipleCircleChartOptions"
                        :colors="colors"
                        :load="tab"
                        ></d-audits>
                    </v-tab-item>

                    <!-- Sales -->
                    <v-tab-item>
                        <d-sales 
                        :colors="colors"
                        :load="tab"
                        ></d-sales>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>    
        </v-container> 
    </div>
</template>

<script>

export default {
	components: {
        headers(){
            return [
                { text: this.$t('payments.client'), value: 'client_id' },
            ];
        }
    },
    data(){
        return {
            tab: 0,
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            tab_items: [
                this.$t('dashboard.operation'), 
                this.$t('dashboard.fulfillment'), 
                this.$t('dashboard.supervision'), 
                this.$t('dashboard.audits'), 
                this.$t('dashboard.sales')
            ],
            colors:[
                "#D91136",
                "#932BD9",
                "#29A632",
                "#F2BB13",
                "#049DD9",
                "#F28B0C",
                "#FA23E1",
                "#0EEDE4",
                "#EAF205",
                "#BE56F5",
                "#03A688",
                "#0468BF"
            ],
            yearChartOptions: {
				scales: {
					xAxes: [{
						stacked: true,
                        padding: 0,
                        ticks: {
                            autoSkip: false,
                            minor: {
                                fontSize: 10
                            },
                            // maxRotation: 20,
                            // minRotation: 20
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
					}],
					yAxes: [{
						stacked: true,                
                        padding: 0,
                        ticks: {
                            beginAtZero: true,
                            minor: {
                                fontSize: 10
                            }
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        },
					},],
				},
				legend: { display: true, position: 'top'},
				maintainAspectRatio: false,
				cutoutPercentage: 0,
                responsive: false,
                lineTension: 1,
			},
            sideChartOptions: {
				scales: {
					xAxes: [{
						stacked: true,
                        padding: 0,
                        ticks: {
                            autoSkip: false,
                            minor: {
                                fontSize: 10
                            },
                            // maxRotation: 20,
                            // minRotation: 20
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
					}],
					yAxes: [{
						stacked: true,                
                        padding: 0,
                        ticks: {
                            beginAtZero: true,
                            minor: {
                                fontSize: 10
                            }
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        },
					},],
				},
				legend: { display: true, position: 'right'},
				maintainAspectRatio: false,
				cutoutPercentage: 0,
                responsive: true,
                lineTension: 1,
			},
            totalAppointmentsOptions: {
				maintainAspectRatio: true,
                responsive: true,
				cutoutPercentage: 75,
				legend: { display: true, position: 'right', labels: { fontSize: 10, padding: 4, margin: 30 } }
			},
            multipleCircleChartOptions: {
				maintainAspectRatio: true,
                responsive: true,
				cutoutPercentage: 75,
				legend: { display: true, position: 'bottom', labels: { fontSize: 10, padding: 4, margin: 30 } }
			},
            periodo: this.$t('dashboard.weekly'),
            periodos: [
                this.$t('dashboard.weekly'),
                this.$t('dashboard.monthly'),
                this.$t('dashboard.annual'),
            ],
            anio: new Date().getFullYear(),
            anios: [ 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027],
            currentWeek: '',
            weeks: [],
            months: [
                { month: 1, es: 'Enero', en: 'January', days: 31},
                { month: 2, es: 'Febrero', en: 'February', days: 28},
                { month: 3, es: 'Marzo', en: 'March', days: 31},
                { month: 4, es: 'Abril', en: 'April',days: 30},
                { month: 5, es: 'Mayo', en: 'May', days: 31},
                { month: 6, es: 'Junio', en: 'June', days: 30},
                { month: 7, es: 'Julio', en: 'July', days: 31},
                { month: 8, es: 'Agosto', en: 'August', days: 31},
                { month: 9, es: 'Septiembre', en: 'September', days: 30},
                { month: 10, es: 'Octubre', en: 'October', days: 31},
                { month: 11, es: 'Noviembre', en: 'November', days: 30},
                { month: 12, es: 'Diciembre', en: 'December', days: 31},
            ],
            monthsNames: [],
            currentMonth : '',
            initialDate: '',
            finalDate: '',
        }
    },
    mounted(){
        this.getWeeksInMonth();

        this.$store.state.module = "headers.dashboard";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        getWeeksInMonth() {
            let year = this.anio;
            let currentMonth = 0;
            let count = 1;
            const d = new Date();
            let day = d.getDate();
            let month = d.getMonth();
            let totalDays = ((year % 4 === 0 && year % 100 > 0) || year % 400 == 0) ? 366 : 365;
            if(totalDays == 366) this.months[1].days = 29;

            for(let i = 1; i <= totalDays; i++){
                if(currentMonth > 11) break;
                if(count <= this.months[currentMonth].days){
                    let start = count;
                    if(count + 6 > this.months[currentMonth].days){
                        
                        if(currentMonth >= 11){
                            this.weeks.push(start + '/' + this.months[currentMonth][this.$store.state.language] + ' - ' + this.months[currentMonth].days + '/' + this.months[currentMonth][this.$store.state.language]);

                            if(month == currentMonth && day >= start) this.currentWeek = start + '/' + this.months[currentMonth][this.$store.state.language] + ' - ' + this.months[currentMonth].days + '/' + this.months[currentMonth][this.$store.state.language];
                            break;
                        }

                        count = (count + 6) - this.months[currentMonth].days;
                        let end = count;
                        currentMonth += 1;
                        this.weeks.push(start + '/' + this.months[currentMonth - 1][this.$store.state.language] + ' - ' + end + '/' + this.months[currentMonth][this.$store.state.language]);

                        if((month == currentMonth - 1 && day >= start)) this.currentWeek = start + '/' + this.months[currentMonth - 1][this.$store.state.language] + ' - ' + end + '/' + this.months[currentMonth][this.$store.state.language];
                        else if ((month == currentMonth && day <= end)) this.currentWeek = start + '/' + this.months[currentMonth - 1][this.$store.state.language] + ' - ' + end + '/' + this.months[currentMonth][this.$store.state.language];
                        count++;
                    }
                    else {
                        this.weeks.push(start + '/' + this.months[currentMonth][this.$store.state.language] + ' - ' + (count + 6) + '/' + this.months[currentMonth][this.$store.state.language]);
                        
                        if(month == currentMonth && day >= start && month == currentMonth && day <= (count + 6)) this.currentWeek = start + '/' + this.months[currentMonth][this.$store.state.language] + ' - ' + (count + 6) + '/' + this.months[currentMonth][this.$store.state.language];

                        count += 7;
                        if(count + 1 > this.months[currentMonth].days){
                            count = 1;
                            currentMonth += 1;
                        }
                    }
                    
                    i += 5;
                }
            }
        },
    },
    watch:{
        'periodo': function(){
            if(this.periodo == this.$t('dashboard.monthly')){
                if(this.$store.state.language == 'es') this.monthsNames = [
                    'Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
                ];
                else this.monthsNames = [
                    'January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
                ];
                const d = new Date();
                this.currentMonth = this.monthsNames[d.getMonth()];
                this.initialDate = this.anio + '-'+ (d.getMonth() + 1).toString().padStart(2, '0') + '-01';
                this.finalDate = this.anio + '-'+ (d.getMonth() + 1).toString().padStart(2, '0') + '-' + this.months[d.getMonth()].days;
                
            }
            else if(this.periodo == this.$t('dashboard.weekly')){
                const d = new Date();
                let dates = this.currentWeek.split('-');
                this.initialDate = this.anio + '-'+ (d.getMonth() + 1).toString().padStart(2, '0') + '-' + dates[0].split('/')[0].trim().padStart(2, '0');
                this.finalDate = this.anio + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + dates[1].split('/')[0].trim().padStart(2, '0');
                
            }
            else if(this.periodo == this.$t('dashboard.annual')){
                this.initialDate =  this.anio.toString() + '-01-01';
                this.finalDate = this.anio.toString() + '-12-31';
            }
        },
        'currentWeek': function(){
            if(this.$store.state.language == 'es') this.monthsNames = [
                'Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];
            else this.monthsNames = [
                'January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
            ];

            let dates = this.currentWeek.split('-');
            let month1 = this.monthsNames.indexOf(dates[0].split('/')[1].trim());
            let month2 = this.monthsNames.indexOf(dates[1].split('/')[1].trim());
            this.initialDate = this.anio + '-'+ (month1 + 1).toString().padStart(2, '0') + '-' + dates[0].split('/')[0].trim().padStart(2, '0');
            this.finalDate = this.anio + '-' + (month2 + 1).toString().padStart(2, '0') + '-' + dates[1].split('/')[0].trim().padStart(2, '0');
        },
        'currentMonth': function(){
            if(this.$store.state.language == 'es') this.monthsNames = [
                'Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];

            else this.monthsNames = [
                'January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
            ];
            
            let month = this.monthsNames.indexOf(this.currentMonth);
            this.initialDate = this.anio + '-'+ (month + 1).toString().padStart(2, '0') + '-01';
            this.finalDate = this.anio + '-'+ (month + 1).toString().padStart(2, '0') + '-' + this.months[month].days;
        },
        'anio': function(){
            if(this.periodo == this.$t('dashboard.weekly')){
                if(this.$store.state.language == 'es') this.monthsNames = [
                    'Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
                ];
                else this.monthsNames = [
                    'January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
                ];

                let dates = this.currentWeek.split('-');
                let month1 = this.monthsNames.indexOf(dates[0].split('/')[1].trim());
                let month2 = this.monthsNames.indexOf(dates[1].split('/')[1].trim());
                this.initialDate = this.anio + '-'+ (month1 + 1).toString().padStart(2, '0') + '-' + dates[0].split('/')[0].trim().padStart(2, '0');
                this.finalDate = this.anio + '-' + (month2 + 1).toString().padStart(2, '0') + '-' + dates[1].split('/')[0].trim().padStart(2, '0');
            }
            else if(this.periodo == this.$t('dashboard.monthly')){
                if(this.$store.state.language == 'es') this.monthsNames = [
                    'Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
                ];

                else this.monthsNames = [
                    'January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
                ];
                
                let month = this.monthsNames.indexOf(this.currentMonth);
                this.initialDate = this.anio + '-'+ (month + 1).toString().padStart(2, '0') + '-01';
                this.finalDate = this.anio + '-'+ (month + 1).toString().padStart(2, '0') + '-' + this.months[month].days;
            }
            else if(this.periodo == this.$t('dashboard.annual')){
                this.finalDate = this.anio.toString() + '-12-31';
                this.initialDate =  this.anio.toString() + '-01-01';
            }
        }
    }
}
</script>

<style>

</style>
