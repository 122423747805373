<template>
    <div>
        <v-autocomplete
            :items="clients"
            :label="$t('client_selector.client')"
            item-value="id" 
            item-text="name"
            v-model="$store.state.clientId"
            :disabled="disabled"
            class="py-0 mb-1"
        ></v-autocomplete>
    </div>
</template>

<script>
export default {
    props: [
        'addAllOption', 
        'disabled',
        'is_national', // 0 = international; 1 = national; 2 = all
        'is_fulfillment',
        'only_fulfillment',
        'cod', // optiona -> if exist then only cod clients
        'yego_clients'
    ],
    data(){
        return {
            clients: [],
        }
    },
    mounted(){
        if(this.yego_clients == true) this.getYegoClients();
        else if(this.only_fulfillment == undefined || this.only_fulfillment == null) this.getClients();
        else if(this.only_fulfillment == 1) this.getFulfillmentClients(this.cod);
    },
    methods: {
        getClients(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/' + this.is_national, { headers: {Authorization: lsToken} })
            .then((response) => {
                response.data = response.data.data;
                if(response.status === 200){
                    response.data.forEach(x => {
                        x = x.attributes;
                        this.clients.push({name: x.name, id: x.id})
                    })

                    this.clients = this.clients.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));

                    if(typeof this.addAllOption !== "undefined" && this.addAllOption){
                        this.clients.unshift({
                            id: 'all',
                            name: this.$t("client_selector.all") 
                        })
                    }
                    if(typeof this.disabled !== "undefined" && this.disabled){
                        this.disabled = true;
                    }
                    // if(typeof this.is_fulfillment !== undefined){
                        
                    // }
                }
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = true;
                error
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        getFulfillmentClients(cod){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            var url = 'clientsFulfillment';

            if(cod == 1) url = 'clientsFulfillment?cod=1';
            else url = 'clientsFulfillment';

            this.$http.get(this.$store.state.chronosApi + url,{ headers: {Authorization: lsToken} })
            .then((response) => {
                response.data = response.data.data;
                if(response.status === 200){
                    response.data.forEach(x => {
                        x = x.attributes;
                        this.clients.push({name: x.name, id: x.id})
                    })

                    this.clients = this.clients.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));

                    if(typeof this.addAllOption !== "undefined" && this.addAllOption){
                        this.clients.unshift({
                            id: 'all',
                            name: this.$t("client_selector.all") 
                        })
                    }
                    if(typeof this.disabled !== "undefined" && this.disabled){
                        this.disabled = true;
                    }
                }
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = true;
                error
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        getYegoClients(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            this.$http.get(this.$store.state.chronosApi + 'fulfillment/reports/allowed_clients', { headers: {Authorization: lsToken} })
            .then((response) => {
                this.clients = response.data.data;
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = true;
                error
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        emitClients(){
            this.$emit('getClients', this.clients)
        }
    },
    watch: {
        '$store.state.clientId': function(){
            if(this.$store.state.clientId != "all" && this.$store.state.clientId != undefined && this.$store.state.clientId != null && this.clients.length > 0){
                this.$store.state.clientName = this.clients.find(x => x.id == this.$store.state.clientId).name;
            } 
        }
    }
}
</script>

<style>

</style>