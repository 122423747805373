<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="2" class="py-0 my-0">
                    <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :only_fulfillment="1" :is_national="2" :cod="1"></client-selector>  
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-autocomplete class="py-0" v-model="shipping_service" :items="shipping_services" item-value="name" item-text="text" :label="$t('delivered.shipping_service')" :rules="rules_required"></v-autocomplete> 
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="initial_date" v-model="menu" :close-on-content-click="false" :return-value.sync="initial_date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="initial_date" :label="$t('delivered.initial_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="initial_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">{{ $t('delivered.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.initial_date.save(initial_date)">{{ $t('delivered.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="final_date" v-model="menu1" :close-on-content-click="false" :return-value.sync="final_date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="final_date" :label="$t('delivered.final_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="final_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu1 = false">{{ $t('delivered.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.final_date.save(final_date)">{{ $t('delivered.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('stock_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>

                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('delivered.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="px-0">
                    <v-col class="py-0">
                        <v-row class="azul font-weight-bold text-uppercase white--text px-2 py-0">
                            <v-col cols="4" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.shipments_in_period') }}:</span> {{ summary.shipments }}</v-col>
                            <v-col cols="4" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.cod_sent') }}:</span> {{ summary.cod }}</v-col>
                            <v-col cols="4" class="py-1"><span class="font-weight-bold text-u1ppercase">{{ $t('delivered.deliveries_in_period') }}:</span> {{ summary.deliveries }}</v-col>         
                        </v-row>
                        <v-row class="azul font-weight-bold text-uppercase white--text px-2 py-0">
                            <v-col cols="4" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.cod_delivered') }}:</span> {{ summary.cod_deliveries }}</v-col>
                            <v-col cols="4" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.delivered') }}:</span> {{ summary.delivered }}</v-col>           
                            <v-col cols="4" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.collection') }}:</span> {{ summary.collected }}</v-col>           
                        </v-row>
                    </v-col>
                </v-card-text>

                <v-card-text class="pt-0 pb-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers: headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('delivered.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('delivered.destination'), align: "center", value: 'state', class: 'text-uppercase' },
                { text: this.$t('delivered.shipping_service'), align: "center", value: 'shipping_service', class: 'text-uppercase' },
                { text: this.$t('delivered.shipments'), align: "center", value: 'shipments', class: 'text-uppercase' },
                { text: this.$t('delivered.cod_shipments'), align: "center", value: 'cod_shipments', class: 'text-uppercase' },
                { text: this.$t('delivered.deliveries'), align: "center", value: 'deliveries', class: 'text-uppercase' },
                { text: this.$t('delivered.cod_deliveries'), align: "center", value: 'cod_deliveries', class: 'text-uppercase' },
                { text: this.$t('delivered.porcent_deliveries'), align: "center", value: 'percentage', class: 'text-uppercase' },
                { text: this.$t('delivered.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            initial_date: '',
            final_date: '',
            shipping_service: '',
            shipping_services: [],
            menu: false,
            menu1: false,
            summary: {
                shipments: 0,
                cod: 0,
                deliveries: 0,
                cod_deliveries: 0,
                delivered: 0,
                collected: 0
            },
            actions: [
                {
                    name: this.$t('products.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                    permission: "product.show"
                }
            ],
            show_export_options: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.delivered";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
        },
        generate(){
            this.props.items = [];
            this.summary = {
                shipments: 0,
                cod: 0,
                deliveries: 0,
                cod_deliveries: 0,
                delivered: 0,
            };
            
            if(this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/delivered?client_id='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date + '&shippingService=' + this.shipping_service, {headers: {Authorization: lsToken}})
                .then((response) => {
                    const items = Object.keys(response.data.data).map((key) => response.data.data[key]);
                    items.forEach(x => {
                        const states = Object.keys(x)
                        states.forEach(y => {
                            const ss = Object.keys(x[y])
                            ss.forEach(z => {
                                x[y][z].cod_shipments = "$" + this.numberFormat.format(x[y][z].cod_shipments);
                                x[y][z].cod_deliveries = "$" + this.numberFormat.format(x[y][z].cod_deliveries);
                                x[y][z].percentage = x[y][z].percentage + "%";

                                x[y][z]['actions'] = JSON.parse(JSON.stringify(this.actions));
                                x[y][z].actions[0].link = '/tnt_delivered_details/' + x[y][z].date + '/' + this.$store.state.clientId;
                                this.props.items.push(x[y][z]);
                            })
                        })
                    });

                    this.summary = response.data.summary;
                    this.summary.cod = "$" + this.numberFormat.format(this.summary.cod);
                    this.summary.cod_deliveries = "$" + this.numberFormat.format(this.summary.cod_deliveries);
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    console.log(response)
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('delivered.error_getting_data')
                };
            }
        },
        exportDataExcel(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;
                
                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/delivered_export?client_id='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date + '&shippingService=' + this.shipping_service, {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Delivered.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('delivered.error_getting_data')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('delivered.error_getting_data')
                };
            }
        },
        exportDataPdf(){
            // const lsToken = localStorage.getItem("access_token");
            // if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
            //     this.$store.state.loaderProps.visible = true;

            //     this.$http.get(this.$store.state.chronosApi + 'reports/operationsByClientPdf?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date + '&shippingService=' + this.shipping_service, {headers: {Authorization: lsToken}, responseType:'blob'})
            //     .then(response => {
            //         this.$store.state.loaderProps.visible = false; 
            //         FileSaver.saveAs(response.data, 'Operaciones_Por_Cliente.pdf');
            //     })
            //     .catch(() => {
            //         this.$store.state.loaderProps.visible = false;
            //         this.snackbar = {
            //             visible: true,
            //             color: 'error',
            //             text: this.$t('delivered.error_getting_data')
            //         };
            //     })
            // }
            // else {
            //     this.snackbar = {
            //         visible: true,
            //         color: 'error',
            //         text: this.$t('delivered.error_getting_data')
            //     };
            // }
        }
    },
    watch: {
        '$store.state.clientId': function(){
            this.props.items = [];
            this.shipping_services = [];

            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            this.$http.get(this.$store.state.chronosApi + 'trackNTrace/get_customer_shipping_services?client_id=' + this.$store.state.clientId, {headers: {Authorization: lsToken}})
            .then(response => {
                this.shipping_services.push({name: "ALL", text: this.$t('delivered.all')});
                response.data.shipping_services.forEach(x => {
                    this.shipping_services.push({name: x.name, text: x.name});
                })
                this.$store.state.loaderProps.visible = false;
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('delivered.error_getting_data')
                };
            })
        }
    }
}
</script>

<style>
</style>